export class Drink {

    constructor(
        public id :string,
        public nome :string,
        public desc :string,
        public prezzo :string,
        public acceso :string
    ){}
}
