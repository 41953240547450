<div class="row mx-0 px-0 justify-content-center sfondosquamato pb-100">
    <div class="col-md-6 mt-200 row justify-content-center">
        <h2 class="col-md-12 pag-tit text-center" i18n>MENÙ DEGUSTAZIONI</h2>
        <div class="lineablu "></div>



        <div class="col-md-12"></div>
        <h5 class="col-md-12 tit-azz text-center fs-4" i18n>SUMMERTIME</h5>
        <div class="col-md-7 mt-5 text-center">
            <h3 i18n>Il menù comprende:</h3>

            <p class="mt-5" i18n>
                <b>Cozze in purea di zucca</b><br />
                <b>Selezione di marinati dello Chef</b><br/>
                <b>Chicche di patate Vongola e bottarga</b><br>
                <b>Tris di grigliati Bianchi</b><br>
                <b>(Orata, Spigola, Persico)</b><br>
            </p>
            <h4 class="tit-azz"> €28</h4> <b> a persona, minimo 2 persone, bevande escluse.</b>

        </div>

        <h5 class="col-md-12 tit-azz text-center fs-4" i18n>IN RIVA AL MARE</h5>
        <div class="col-md-7 mt-5 text-center">
            <h3 i18n>Il menù comprende:</h3>

            <p class="mt-5" i18n>
                <b>Insalata di mare</b><br />
                <b>Selezione di gratinati</b><br />
                <b>Gnocchetti alle cannocchie</b><br>
                <b>Fettuccine mari e monti</b><br>
                <b>Grigliata mista tonno, slamone e spada agli agrumi e verdure</b><br>
                <b>DESSERT a scelta</b><br>
            </p>
            <h4 class="tit-azz"> €46</h4> <b> a persona, minimo 2 persone, bevande escluse.</b>

        </div>
    </div>
</div>
