<div class="row mx-0 px-0 justify-content-center sfondosquamato pb-100">
    <div class="col-md-6 mt-200 row justify-content-center">
        <h2 class="col-md-12 pag-tit text-center" i18n>LAVORA CON NOI</h2>
        <div class="lineablu "></div>
        <h5 class="col-md-12 tit-azz text-center" i18n>Unisciti alla ciurma!</h5>

        <div class="col-md-5">
            <a class="bottoneasy text-center" href="mailto:info@fisheasy.it" i18n>Invia Candidatura spontanea</a>
        </div>
<div class="col-md-12"></div>

        <div class="col-md-5 mt-100 text-center">
            <h3 i18n>Posizioni aperte</h3>

            <h5 class="tit-azz mt-5" i18n>Cameriere</h5>
            <h5 class="tit-azz" i18n>Aiuto Cuoco</h5>
            <h5 class="tit-azz" i18n>Responsabile di sala</h5>
        </div>
    </div>
</div>
