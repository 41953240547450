<div class="row m-0 p-0 justify-content-center" >
    <div class="col-md-8 row mx-0 p-0 justify-content-center mt-200">
        <div class="col-md-6 gruppetto">
            <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde-mp">
            <img src="assets/img/imm1_materieprime.jpg" class="imm-sov imm-vongole">
        </div>


        <div class="col-md-6 ">
            <h2 class="pag-tit text-center" i18n>MATERIE PRIME</h2>
            <div class="lineablu"></div>
            <p i18n>Il pesce del nostro Menù è in grande maggioranza fresco (eventuali prodotti surgelati sono evidenziati a margine del piatto),  pescato e allevato è buono, perché semplice e naturale, con in più solo le cure del nostro Chef</p>
        </div>
    </div>

    <div class="col-md-12 row mx-0 px-0 justify-content-center ">
        <div class="col-md-8 row mx-0 px-0 justify-content-center">
            <div class="col-md-4 pt-5">
                <div class="lineablu"></div>
            <p i18n>Puliamo, spiniamo e lavoriamo
                internamente tutto il pesce che utilizziamo
                e lo trasformiamo nei piatti che
                proponiamo nel menu: dai burger, ai filetti
                fino ai tranci.</p>
            </div>

        </div>
    </div>
<div class="col-md-12 m-0 p-0">
    <video class="video-materie p-0" autoplay loop muted playsinline oncanplay="this.play()"
    onloadedmetadata="this.muted = true">
    <source src="assets/img/lafiliera.mp4" type="video/mp4">

    Your browser does not support the video tag.
</video>
</div>



    <div class="col-md-12 row mx-0 px-0 justify-content-center ">
        <div class="col-md-8 row mx-0 px-0 justify-content-center">

            <div class="col-md-4 pt-5">

            <p i18n>La ricetta che distingue Fisheasy è
                l'abbinamento di agrumi, spezie, insalate,
                cereali, frutti e verdure al gusto deciso del
                pesce, per offrirvi un'esperienza culinaria
                sana, originale e dal gusto mediterraneo.</p>
            </div>

        </div>
    </div>


    <div class="col-md-8 row mx-0 p-0 justify-content-center mt-md-200">



        <div class="col-md-6 ">
            <h2 class="pag-tit text-center" i18n>VINI & ACQUE</h2>
            <div class="lineablu"></div>
            <p i18n>La nostra carta dei vini offre un'ampia
                scelta di etichette, dai produttori di vini
                biologici e naturali alle maggiori cantine
                locali umbre e nazionali.</p>

               <p i18n> Insieme alla carta dei vini, proponiamo anche un assortito menu delle acque, selezionate per le loro
                proprietà benefiche perché, a differenza del vino, che viene generalmente scelto sulla base di cosa si
                mangia, l'acqua si sceglie in base alla persona che la beve e alle sue esigenze salutistiche.<br/>Ottime per accompagnare piatti sani e sfiziosi.</p>
        </div>


        <div class="col-md-6 gruppetto">
            <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde-mp2">
            <img src="assets/img/imm2_materieprime.jpg" class="imm-sov imm-lattina">
            <img src="assets/img/imm3_materieprime.png" class="imm-sov imm-bottiglia">
        </div>
    </div>

</div>

<div class="col-12 divisore"></div>
