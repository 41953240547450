import { Component, OnInit } from '@angular/core';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-lavora',
  templateUrl: './lavora.component.html',
  styleUrls: ['./lavora.component.css']
})
export class LavoraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


    window.scrollTo(0, 0);
   
    AOS.init({
      duration: 3000
    });

    var menu = document.getElementById('check') as HTMLInputElement;
    if (menu.checked == true) {
      menu.checked = false;
    }
  }

}
