<div class="row m-0 p-0 justify-content-center">
    <div class="col-md-8 row mx-0 p-0 justify-content-center mt-200">
        <div class="col-md-6 gruppetto">
            <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde">
            <img src="assets/img/imm2_easyathome.png" class="imm-sov imm-salad">
            <img src="assets/img/imm1_easyathome.png" class="imm-sov imm-burger">
        </div>


        <div class="col-md-6 ">
            <h2 class="pag-tit text-center" i18n>EASY AT HOME</h2>
            <div class="lineablu"></div>
            <p i18n>
                Puoi gustare i nostri piatti in asporto nei comodi ed ecocompatibili box Fisheasy, oppure,  se sei a Foligno presso il tuo domicilio, cercaci su Glovo e Deliveroo, la bontà del mare direttamente a casa tua.</p>
        </div>
    </div>
</div>
