<div class="row m-0 p-0 justify-content-center overflow-hidden">
    <div class="col-12 hero-home ">
        <div class="col-md-12 p-0 row m-0 justify-content-center">
            <video class="video-home p-0" autoplay loop muted playsinline oncanplay="this.play()"
                onloadedmetadata="this.muted = true">
                <source src="assets/img/fisheasy.mp4" type="video/mp4">

                Your browser does not support the video tag.
            </video>
        </div>
    </div>
    <div class="col-md-8 mx-0 px-0 row justify-content-center home-main pb-200">
        <h2 id="titolon" class="col-md-8 text-center" i18n>IL NOSTRO</h2>
        <a class="col-md-8 menu-link" routerLink="/menu">
            <h1 class=" text-center" i18n>MENÙ</h1>
        </a>

        <div class=" col-12 mx-0 px-0">

            <img class="alga_sx" src="assets/img/alga_sx.png">
            <img class="alga_dx" src="assets/img/alga_dx.png">
            <div class=" slider slider-home d-block d-md-none ">


                <div>
                    <a routerLink="/menu"><img src="assets/img/tris_marinati.png"></a>
                </div>
                <div>
                    <a routerLink="/menu"><img src="assets/img/fishburger_stick_3.png"></a>
                </div>
                <div>
                    <a routerLink="/menu"><img src="assets/img/filettietranci1_tonnoavocado.png"></a>
                </div>
                <div>
                    <a routerLink="/menu"><img src="assets/img/filettietranci2_muggine.png"></a>
                </div>
                <div>
                    <a routerLink="/menu"><img src="assets/img/filettietranci3_branzino.png"></a>
                </div>

                <div>
                    <a routerLink="/menu"><img src="assets/img/fishburger_stick_2.png"></a>
                </div>
                <div>
                    <a routerLink="/menu"><img src="assets/img/fishsalad_2.png"></a>
                </div>
            </div>

            <div class=" slider slider-home d-none d-md-block">


                <div>
                    <img src="assets/img/tris_marinati.png">
                </div>
                <div>
                    <img src="assets/img/filettietranci1_tonnoavocado.png">
                </div>
                <div>
                    <img src="assets/img/filettietranci2_muggine.png">
                </div>
                <div>
                    <img src="assets/img/filettietranci3_branzino.png">
                </div>

                <div>
                    <img src="assets/img/fishburger_stick_2.png">
                </div>
                <div>
                    <img src="assets/img/fishsalad_2.png">
                </div>
            </div>

        </div>


    </div>

    <div class=" col-12 row mx-0 my-0 justify-content-center">
<div class="col-md-6 mt-100" *ngIf="!ing">
        <img src="assets/img/ribbon_promo1.png">
</div>

<div class="col-md-6 mt-100" *ngIf="ing">
         <img  src="assets/img/ribbon_promo2.png">
</div>
        <div class="col-md-12"></div>

        <div class="col-md-8 row mx-0 p-0 justify-content-center  mt-200">
            <div class="col-md-6 gruppetto">
                <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde">
                <img src="assets/img/IMM2_promo.jpg" class="imm-sov imm-promo2">
                <img src="assets/img/menu/fishburger_bianco3.jpg" class="imm-sov imm-promo1">
                <img src="assets/img/menu/insalatafantasia_salmonemarinato3.jpg" class="imm-sov imm-promo3">


            </div>
            <div class="col-md-6">

                <h2 class="tit-home text-center"  i18n>EASY LUNCH e CORTESIA</h2>

                <p  i18n>
                    Per una pausa pranzo alternativa, sana e
                    gustosa, ti proponiamo due menù da 10
                    e 15 € da gustare nel nostro Bistrò a
                    pochi metri da Piazza della Repubblica. E
                    se non puoi venire da noi, ordina e ritira il
                    tuo pranzo!<br /><br />

                    La nostra promo è attiva tutto l'anno.</p>

                <a class="a-home" routerLink="/easy-lunch"  i18n>Scopri i menù pranzo</a>
                <br /><br />
                <br /><br />
                <h2 class="tit-home text-center"  i18n>MENÙ DEGUSTAZIONI</h2>

                <p  i18n>
                    <b>Summertime</b> e <b>In riva al mare</b> sono le
                    proposte dello Chef per degustare la
                    nostra cucina di mare. Due menù ricchi e
                    variegati per esplorare i piatti cult di
                    Fisheasy Bistrò.
                </p>

                <a class="a-home" routerLink="/proposte-dello-chef"  i18n>Scopri le Proposte dello Chef</a>
            </div>
        </div>
    </div>
</div>
