<div class="row mx-0 px-0 justify-content-center sfondosquamato">
    <div class="col-md-6 mt-200 row justify-content-center">
        <h2 class="col-md-12 pag-tit text-center"  i18n>CONTATTI</h2>
        <div class="lineablu "></div>
<div class="col-md-12"></div>
        <div class="col-md-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2920.1039446719706!2d12.700951315761625!3d42.95501390531278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132e8545ab2e1601%3A0x54325bd140864f30!2sFishEasy!5e0!3m2!1sen!2sit!4v1632757162850!5m2!1sen!2sit" width="300vw" height="300vw" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="col-md-8 ">
            <a class="riga">
                <img class="icon" src="assets/img/emailblue_icon.png"> <h5 class="cont-riga">info@fisheasy.it</h5>
            </a>

            <a class="riga">
                <img class="icon" src="assets/img/phoneblue_icon.png"> <h5 class="cont-riga">0742 718709</h5>
            </a>

            <a class="riga">
                <a href=" https://www.instagram.com/fisheasy_seaandsalad/"><img class="icon" src="assets/img/instablue_icon.png"> <h5 class="cont-riga">@fisheasy_seaandsalad</h5></a>
            </a>

            <a class="riga">
                <a href="https://www.facebook.com/fisheasyristorante"><img class="icon" src="assets/img/fbblue_icon.png"> <h5 class="cont-riga">@Fisheasy</h5></a>
            </a>

            <a class="riga">
                <a href="https://www.tripadvisor.com/Restaurant_Review-g227667-d23621786-Reviews-Fisheasy-Foligno_Province_of_Perugia_Umbria.html"><img class="icon" src="assets/img/trip_icon.png"> <h5 class="cont-riga">Fisheasy</h5></a>
            </a>

            <a class="riga">
                <a href="https://www.thefork.com/restaurant/fisheasy-r711105"> <img class="icon" src="assets/img/thefork_icon.png"> <h5 class="cont-riga">Fisheasy</h5></a>
            </a>

            <!-- <a class="riga">
              <a href=""> <img class="icon" src="">
                <h5 class="cont-riga">Glovo</h5>
              </a>
            </a>

            <a class="riga">
              <a href="">
                <h5 class="cont-riga quandoo-widget-builder" data-config='{"format":"text-button","bgcolor":"transparent","txcolor":"#54BCD6","round":"no","position":"","font":"md","merchant":96617,"txt":"Quandoo - Prenota ora"}'></h5>
              </a>
            </a>

            <a class="riga">
              <a href=""> <img class="icon" src="">
                <h5 class="cont-riga">Deliveroo</h5>
              </a>
            </a> -->

        </div>
    </div>

    <div class="col-md-12 divisore"></div>
</div>
