<div class="row mx-0 px-0 justify-content-center sfondosquamato pb-100">
    <div class="col-md-6 mt-200 row justify-content-center">
        <h2 class="col-md-12 pag-tit text-center"  i18n>EASY LUNCH</h2>
        <div class="lineablu "></div>



        <div class="col-md-12"></div>
        <h5 class="col-md-12 tit-azz text-center fs-4"  i18n>ADRIATICO 15€</h5>
        <div class="col-md-7 mt-5 text-center">
            <h3  i18n>Il menù comprende:</h3>

            <p class="mt-5" i18n>
                OLIVE DI MARE/POLPETTE DI PESCE (3+3)<br />
                un piatto a scelta tra:<br />
                -FISHBURGER BIANCO<br>
                -FISBURGER AZZURRO<br>
                -SALMONFISH SALAD<br>
                ACQUA CL 33 + COPERTO
            </p>
        </div>

        <h5 class="col-md-12 tit-azz text-center fs-4" i18n>TIRRENO 18€</h5>
        <div class="col-md-7 mt-5 text-center">
            <h3 i18n>Il menù comprende:</h3>

            <p class="mt-5" i18n>
                OLIVE DI MARE/POLPETTE DI PESCE (4+4)<br />
                un piatto a scelta tra:<br />
                -ORATA AGLI ORTAGGI<br>
                -MUGGINE CON POMODORINI, OLIVE TAGGIASCHE E FIOR DI CAPPERO<br>
                ACQUA CL 33 + COPERTO
            </p>
        </div>
        <h5 class="col-md-12 tit-azz text-center fs-4" i18n>CORTESIA</h5>
        <div class="col-md-7 mt-5 text-center">
            <p i18n>Piatto cortesia Vegano </p><h4>€14,00</h4>
            <p i18n>Piatto cortesia Vegetariano </p><h4>€12,50</h4>
            <p i18n>Piatto cortesia carne </p><h4>€16,00</h4>
            <p i18n>Piatto cortesia per Celiaci </p><h4>€14,00</h4>
        </div>
    </div>
</div>
