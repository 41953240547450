<div class="row m-0 p-0  justify-content-center footer">

    <div class="col-6 col-centered footer-divider"></div>


    <div class="col-md-8 row m-0 p-0 my-5 justify-content-center">
        <div class="col-md-4 text-center text-md-left mt-5">
            <p i18n>FishEasy<br/>
                Piazza Matteotti 7, <br/>
                Foligno (PG) 06034<br/>
                </p>
        </div>

        <div class="col-md-4 text-center row p-0 mx-0 mt-5">
            <h5 class="col-12" i18n>SEGUICI SU:</h5>
            <div class="col-12">
                <a class="soc-icon" href="https://www.facebook.com/fisheasyristorante"><img src="assets/img/fbblue_icon.png"></a>
                <a class="soc-icon" href="https://www.instagram.com/fisheasy_seaandsalad/"><img src="assets/img/instablue_icon.png"></a>
                <a class="soc-icon" href="https://www.tripadvisor.com/Restaurant_Review-g227667-d23621786-Reviews-Fisheasy-Foligno_Province_of_Perugia_Umbria.html"><img src="assets/img/trip_icon.png"></a>
                <a class="soc-icon" href="https://www.thefork.com/restaurant/fisheasy-r711105"><img src="assets/img/thefork_icon.png"></a>
                <!-- Quandoo, Glovo e Deliveroo -->
                <!-- <a class="soc-icon" href="https://www.quandoo.it/"><img src=""></a>
                <a class="soc-icon" href="https://glovoapp.com/it/it/"><img src=""></a>
                <a class="soc-icon" href="https://deliveroo.it/it/"><img src=""></a> -->
            </div>
        </div>

        <div class="col-md-4 footer-center text-center mt-5">
            <p i18n>Tel. +39 0742 718709<br/>
               Mail: info@fisheasy.it</p>

               <a href="https://www.iubenda.com/privacy-policy/81801518" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
               <a href="https://www.iubenda.com/privacy-policy/81801518/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
        </div>
    </div>
</div>
