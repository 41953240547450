<div class="row m-0 p-0 justify-content-center pag-menu">

  <h2 id="titolone" class="col-md-8 text-center" i18n>IL NOSTRO</h2>
  <h1 class="col-md-8 text-center" i18n>MENÙ</h1>

  <div *ngIf="inglese">
    <div class="sfondonde col-12 row m-0 p-0 justify-content-center">

      <!--############################ANTIPASTI########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-blue menu-antipasti" data-toggle="collapse" href="#collapseAnti" role="button"
           aria-expanded="false" aria-controls="collapseAnti">
          STARTERS
        </a>

      </p>


      <div class="collapse col-8" id="collapseAnti">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of antipastiEn" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>

                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENS</span></h5>

                      <p>{{piatto.allergeni}}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE ANTIPASTI########################################################################-->


      <!--############################PRIMI########################################################################-->

      <p class="col-12 p-0 m-0">
        <a class=" menu-big menu-white menu-primi" data-toggle="collapse" href="#collapsePrimi" role="button"
           aria-expanded="false" aria-controls="collapsePrimi" i18n>
          FIRST COURSES
        </a>

      </p>


      <div class="collapse col-8" id="collapsePrimi">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of primiEn" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENI</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE PRIMI########################################################################-->


      <!--############################TRANCI E FILETTI########################################################################-->

      <p class="col-12 p-0 m-0">
        <a class=" menu-big menu-blue menu-tranci" data-toggle="collapse" href="#collapseTranci" role="button"
           aria-expanded="false" aria-controls="collapseTranci">
          FILLETS & SLICES
        </a>

      </p>


      <div class="collapse col-8" id="collapseTranci">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of tranciEn" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENS</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE TRANCI E FILETTI########################################################################-->


      <!--############################FISHBURGER########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-white menu-burger" data-toggle="collapse" href="#collapseBur" role="button"
           aria-expanded="false" aria-controls="collapseBur">
          FISHBURGER
        </a>

      </p>


      <div class="collapse col-8" id="collapseBur">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of burgerEn" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENS</span></h5>
                      <p>{{piatto.allergeni}}</p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE FISHBURGER########################################################################-->


      <!--############################FISHSALAD########################################################################-->

      <p class="col-12 p-0 m-0">
        <a class=" menu-big menu-blue menu-salad" data-toggle="collapse" href="#collapseSalad" role="button"
           aria-expanded="false" aria-controls="collapseSalad">
          FISHSALAD
        </a>

      </p>


      <div class="collapse col-8" id="collapseSalad">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of saladEn" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENS</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE FISHSALAD########################################################################-->


      <!--############################DOLCI########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-white menu-dolci" data-toggle="collapse" href="#collapseDolci" role="button"
           aria-expanded="false" aria-controls="collapseDolci">
          DESSERTS
        </a>

      </p>


      <div class="collapse col-8" id="collapseDolci">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of dolciEn" class="ripetuti">
            <p>
              <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                 aria-expanded="false" aria-controls="collapseExample">
                <div class="row m-0 p-0 w-100">
                  <div class="col-md-3">
                    <img src="assets/img/menu/{{piatto.img}}">
                  </div>
                  <div class="col-md-6 sottobord">
                    <h3 class="piatto-nome">{{piatto.nome}}</h3>
                    <p>{{piatto.desc}}</p>
                  </div>
                  <div class="col-md-3 prezzo">
                    <h4 [innerHTML]="piatto.prezzo"></h4>
                    <div class="piu">+</div>
                  </div>
                </div>
              </a>

            </p>
            <div class="collapse prod-inner" id="collapse{{piatto.id}}">
              <div class="row m-0 p-0 justify-content-center">
                <div class="col-md-8 row">
                  <div class="col-md-5">
                    <img src="assets/img/menu/{{piatto.immagine}}">

                  </div>
                  <div class="col-md-7">
                    <p>{{piatto.descrizione}}</p>

                    <p [innerHTML]="piatto.ingredienti"></p>
                    <h5><span i18n>ALLERGENS</span></h5>
                    <p>{{piatto.allergeni}}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE DOLCI########################################################################-->


      <!--############################BEVANDE########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-blue menu-bevande" data-toggle="collapse" href="#collapseBev" role="button"
           aria-expanded="false" aria-controls="collapseBev">
          DRINKS
        </a>

      </p>


      <div class="collapse col-8" id="collapseBev">


        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsecock" role="button" aria-expanded="false"
             aria-controls="collapsecock">
            COCKTAIL
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsecock">

          <div *ngFor="let drink of cock " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsepreco" role="button" aria-expanded="false"
             aria-controls="collapsepreco">
            PREMIUM COCKTAIL
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsepreco">

          <div *ngFor="let drink of precock " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebirre" role="button" aria-expanded="false"
             aria-controls="collapsebirre">
            BEERS
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebirre">

          <div *ngFor="let drink of birre " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsearti33" role="button" aria-expanded="false"
             aria-controls="collapsearti33">
            CRAFT BEERS
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsearti33">

          <div *ngFor="let drink of arti33 " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsevini" role="button" aria-expanded="false"
             aria-controls="collapsevini">
            WINES
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsevini">

          <div *ngFor="let drink of vini " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsecalici" role="button" aria-expanded="false"
             aria-controls="collapsecalici">
            GLASSES
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsecalici">

          <div *ngFor="let drink of calici " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebollicine" role="button" aria-expanded="false"
             aria-controls="collapsebollicine">
            SPARKLING WINES
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebollicine">

          <div *ngFor="let drink of bollicine " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsechamp" role="button" aria-expanded="false"
             aria-controls="collapsechamp">
            CHAMPAGNE
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsechamp">

          <div *ngFor="let drink of champ " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseacqua" role="button" aria-expanded="false"
             aria-controls="collapseacqua">
            MINERAL WATERS
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseacqua">

          <div *ngFor="let drink of acqua " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapserum" role="button" aria-expanded="false"
             aria-controls="collapserum">
            RUM
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapserum">

          <div *ngFor="let drink of rum " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsevod" role="button" aria-expanded="false"
             aria-controls="collapsevod">
            VODKA
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsevod">

          <div *ngFor="let drink of vodka " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsegin" role="button" aria-expanded="false"
             aria-controls="collapsegin">
            GIN
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsegin">

          <div *ngFor="let drink of gin " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsever" role="button" aria-expanded="false"
             aria-controls="collapsever">
            VERMOUTH
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsever">

          <div *ngFor="let drink of ver " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseliq" role="button" aria-expanded="false"
             aria-controls="collapseliq">
            LIQUEURS
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseliq">

          <div *ngFor="let drink of liquori " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsewhi" role="button" aria-expanded="false"
             aria-controls="collapsewhi">
            WHISKY
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsewhi">

          <div *ngFor="let drink of whisky " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseteq" role="button" aria-expanded="false"
             aria-controls="collapseteq">
            TEQUILA
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseteq">

          <div *ngFor="let drink of tequila " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebitt" role="button" aria-expanded="false"
             aria-controls="collapsebitt">
            BITTER
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebitt">

          <div *ngFor="let drink of bitter " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->


        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsesoft" role="button" aria-expanded="false"
             aria-controls="collapsesoft">
            SOFT DRINK
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsesoft">

          <div *ngFor="let drink of soft " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsesucchi" role="button" aria-expanded="false"
             aria-controls="collapsesucchi">
            JUICES
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsesucchi">

          <div *ngFor="let drink of succhi " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->


      </div>


      <!--############################FINE BEVANDE########################################################################-->


    </div>
  </div>

  <div *ngIf="!inglese">
    <div class="sfondonde col-12 row m-0 p-0 justify-content-center">

      <!--############################EASY LUNCH########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-blue" data-toggle="collapse" href="#collapseEasyLunch" role="button"
           aria-expanded="false" aria-controls="collapseEasyLunch" i18n>
          EASY LUNCH
        </a>

      </p>


      <div class="collapse col-8" id="collapseEasyLunch">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <app-easy-lunch></app-easy-lunch>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################EASY LUNCH########################################################################-->

      <!--############################PROPOSTE DELLO CHEF########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-white" data-toggle="collapse" href="#collapseProposte" role="button"
           aria-expanded="false" aria-controls="collapseProposte" i18n>
          MENÙ DEGUSTAZIONI
        </a>

      </p>


      <div class="collapse col-8" id="collapseProposte">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <app-proposte></app-proposte>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################PROPOSTE DELLO CHEF########################################################################-->

      <!--############################ANTIPASTI########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-blue menu-antipasti" data-toggle="collapse" href="#collapseAnti" role="button"
           aria-expanded="false" aria-controls="collapseAnti" i18n>
          ANTIPASTI
        </a>

      </p>


      <div class="collapse col-8" id="collapseAnti">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of antipasti" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENI</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE ANTIPASTI########################################################################-->

      <!--############################PRIMI########################################################################-->

      <p class="col-12 p-0 m-0">
        <a class=" menu-big menu-white menu-primi" data-toggle="collapse" href="#collapsePrimi" role="button"
           aria-expanded="false" aria-controls="collapsePrimi" i18n>
          PRIMI
        </a>

      </p>


      <div class="collapse col-8" id="collapsePrimi">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of primi" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENI</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE PRIMI########################################################################-->


      <!--############################TRANCI E FILETTI########################################################################-->

      <p class="col-12 p-0 m-0">
        <a class=" menu-big menu-blue menu-tranci" data-toggle="collapse" href="#collapseTranci" role="button"
           aria-expanded="false" aria-controls="collapseTranci" i18n>
          TRANCI E FILETTI
        </a>

      </p>


      <div class="collapse col-8" id="collapseTranci">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of tranci" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.immagine}}"/>
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENI</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE TRANCI E FILETTI########################################################################-->


      <!--############################FISHBURGER########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-white menu-burger" data-toggle="collapse" href="#collapseBur" role="button"
           aria-expanded="false" aria-controls="collapseBur" i18n>
          FISHBURGER
        </a>

      </p>


      <div class="collapse col-8" id="collapseBur">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of burger" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENI</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE FISHBURGER########################################################################-->


      <!--############################FISHSALAD########################################################################-->

      <p class="col-12 p-0 m-0">
        <a class=" menu-big menu-blue menu-salad" data-toggle="collapse" href="#collapseSalad" role="button"
           aria-expanded="false" aria-controls="collapseSalad" i18n>
          FISHSALAD
        </a>

      </p>


      <div class="collapse col-8" id="collapseSalad">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of salad" class="ripetuti">
            <div
              *ngIf="piatto.acceso == display1 || piatto.acceso == display2 || piatto.acceso == display3 || piatto.acceso == display0 ">
              <p>
                <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  <div class="row m-0 p-0 w-100">
                    <div class="col-md-3">
                      <img src="assets/img/menu/{{piatto.img}}">
                    </div>
                    <div class="col-md-6 sottobord">
                      <h3 class="piatto-nome">{{piatto.nome}}</h3>
                      <p>{{piatto.desc}}</p>
                    </div>
                    <div class="col-md-3 prezzo">
                      <h4 [innerHTML]="piatto.prezzo"></h4>
                      <div class="piu">+</div>
                    </div>
                  </div>
                </a>

              </p>
              <div class="collapse prod-inner" id="collapse{{piatto.id}}">
                <div class="row m-0 p-0 justify-content-center">
                  <div class="col-md-8 row">
                    <div class="col-md-5">
                      <img src="assets/img/menu/{{piatto.immagine}}">

                    </div>
                    <div class="col-md-7">
                      <p>{{piatto.descrizione}}</p>

                      <p [innerHTML]="piatto.ingredienti"></p>
                      <h5><span i18n>ALLERGENI</span></h5>
                      <p>{{piatto.allergeni}}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE FISHSALAD########################################################################-->


      <!--############################DOLCI########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-white menu-dolci" data-toggle="collapse" href="#collapseDolci" role="button"
           aria-expanded="false" aria-controls="collapseDolci" i18n>
          DOLCI
        </a>

      </p>


      <div class="collapse col-8" id="collapseDolci">
        <div>


          <!--#######################PARTE RIPETUTA################################-->
          <div *ngFor="let piatto of dolci" class="ripetuti">
            <p>
              <a class="menu-small row" data-toggle="collapse" href="#collapse{{piatto.id}}" role="button"
                 aria-expanded="false" aria-controls="collapseExample">
                <div class="row m-0 p-0 w-100">
                  <div class="col-md-3">
                    <img src="assets/img/menu/{{piatto.img}}">
                  </div>
                  <div class="col-md-6 sottobord">
                    <h3 class="piatto-nome">{{piatto.nome}}</h3>
                    <p>{{piatto.desc}}</p>
                  </div>
                  <div class="col-md-3 prezzo">
                    <h4 [innerHTML]="piatto.prezzo"></h4>
                    <div class="piu">+</div>
                  </div>
                </div>
              </a>

            </p>
            <div class="collapse prod-inner" id="collapse{{piatto.id}}">
              <div class="row m-0 p-0 justify-content-center">
                <div class="col-md-8 row">
                  <div class="col-md-5">
                    <img src="assets/img/menu/{{piatto.immagine}}">

                  </div>
                  <div class="col-md-7">
                    <p>{{piatto.descrizione}}</p>

                    <p [innerHTML]="piatto.ingredienti"></p>
                    <h5><span i18n>ALLERGENI</span></h5>
                    <p>{{piatto.allergeni}}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--#######################FINE PARTE RIPETUTA################################-->


        </div>

      </div>

      <!--############################FINE DOLCI########################################################################-->


      <!--############################BEVANDE########################################################################-->
      <p class="col-12 p-0 m-0">


        <a class=" menu-big menu-blue menu-bevande" data-toggle="collapse" href="#collapseBev" role="button"
           aria-expanded="false" aria-controls="collapseBev" i18n>
          BEVANDE
        </a>

      </p>


      <div class="collapse col-8" id="collapseBev">

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsecock" role="button" aria-expanded="false"
             aria-controls="collapsecock" i18n>
            COCKTAIL
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsecock">

          <div *ngFor="let drink of cock " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsepreco" role="button" aria-expanded="false"
             aria-controls="collapsepreco" i18n>
            PREMIUM COCKTAIL
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsepreco">

          <div *ngFor="let drink of precock " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebirre" role="button" aria-expanded="false"
             aria-controls="collapsebirre" i18n>
            BIRRE
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebirre">

          <div *ngFor="let drink of birre " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsearti33" role="button" aria-expanded="false"
             aria-controls="collapsearti33" i18n>
            BIRRE ARTIGIANALI
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsearti33">

          <div *ngFor="let drink of arti33 " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->
        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsearti75" role="button" aria-expanded="false"
             aria-controls="collapsearti75" i18n>
            BIRRE ARTIGIANALI Cl75
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsearti75">

          <div *ngFor="let drink of arti75 " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->
        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsevini" role="button" aria-expanded="false"
             aria-controls="collapsevini" i18n>
            VINI cl37,5
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsevini">

          <div *ngFor="let drink of vini " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->
        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseviniRossi" role="button" aria-expanded="false"
             aria-controls="collapseviniRossi" i18n>
            VINI Rossi
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseviniRossi">

          <div *ngFor="let drink of viniRossi" class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->
        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsevini75" role="button" aria-expanded="false"
             aria-controls="collapsevini75" i18n>
            VINI cl75
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsevini75">

          <div *ngFor="let drink of vini75 " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsecalici" role="button" aria-expanded="false"
             aria-controls="collapsecalici" i18n>
            VINO - CALICI
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsecalici">

          <div *ngFor="let drink of calici " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebollicine" role="button" aria-expanded="false"
              aria-controls="collapsebollicine" i18n>
            BOLLICINE & CHAMPAGNE cl 37,5
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebollicine">

          <div *ngFor="let drink of champ37 " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebollicine" role="button" aria-expanded="false"
             aria-controls="collapsebollicine" i18n>
            BOLLICINE cl75
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebollicine">

          <div *ngFor="let drink of bollicine " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsechamp" role="button" aria-expanded="false"
             aria-controls="collapsechamp" i18n>
            CHAMPAGNE
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsechamp">

          <div *ngFor="let drink of champ " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseacqua" role="button" aria-expanded="false"
             aria-controls="collapseacqua" i18n>
            ACQUE MINERALI
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseacqua">

          <div *ngFor="let drink of acqua " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapserum" role="button" aria-expanded="false"
             aria-controls="collapserum" i18n>
            RUM
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapserum">

          <div *ngFor="let drink of rum " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsevod" role="button" aria-expanded="false"
             aria-controls="collapsevod" i18n>
            VODKA
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsevod">

          <div *ngFor="let drink of vodka " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsegin" role="button" aria-expanded="false"
             aria-controls="collapsegin" i18n>
            GIN
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsegin">

          <div *ngFor="let drink of gin " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsever" role="button" aria-expanded="false"
             aria-controls="collapsever" i18n>
            GRAPPE E COGNAC
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsever">

          <div *ngFor="let drink of ver " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseliq" role="button" aria-expanded="false"
             aria-controls="collapseliq" i18n>
            LIQUORI e AMARI
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseliq">

          <div *ngFor="let drink of liquori " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsewhi" role="button" aria-expanded="false"
             aria-controls="collapsewhi" i18n>
            WHISKY
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsewhi">

          <div *ngFor="let drink of whisky " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapseteq" role="button" aria-expanded="false"
             aria-controls="collapseteq" i18n>
            TEQUILA
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapseteq">

          <div *ngFor="let drink of tequila " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsebitt" role="button" aria-expanded="false"
             aria-controls="collapsebitt" i18n>
            BITTER
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsebitt">

          <div *ngFor="let drink of bitter " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->


        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsesoft" role="button" aria-expanded="false"
             aria-controls="collapsesoft" i18n>
            SOFT DRINK
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsesoft">

          <div *ngFor="let drink of soft " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->

        <!--#######################PARTE RIPETUTA################################-->
        <p class="col-12 p-0 m-0">


          <a class="drink-title " data-toggle="collapse" href="#collapsesucchi" role="button" aria-expanded="false"
             aria-controls="collapsesucchi" i18n>
            SUCCHI
          </a>

        </p>
        <div class="drink-container my-5 collapse" id="collapsesucchi">

          <div *ngFor="let drink of succhi " class="ripetuti">

            <a class="menu-small menu-drink row">
              <div class="row m-0 p-0 w-100">

                <div class="col-md-9 sottobord">
                  <h3 class="piatto-nome">{{drink.nome}}</h3>
                  <p>{{drink.desc}}</p>
                </div>
                <div class="col-md-3 prezzo">
                  <h4 [innerHTML]="drink.prezzo"></h4>

                </div>
              </div>
            </a>
          </div>
        </div>
        <!--#######################FINE PARTE RIPETUTA################################-->


      </div>

      <!--############################FINE BEVANDE########################################################################-->


    </div>
  </div>

</div>


<div class="d-md-none divisore col-12 mt-150 mb-100"></div>

