import { Component } from '@angular/core';

import { slideInAnimation, AnimationsComponent } from './animations/animations.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[
    slideInAnimation
  ]
})




export class AppComponent {
  title = 'fisheasy';

  
  
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  };

  cambiaLingua:string;
  

  ngOnInit(): void {

    var checkLingua = document.getElementById("checkLingua") as HTMLElement;
    

    if (checkLingua.innerHTML == "MATERIE PRIME"){
      
     this.cambiaLingua = "<a href=\"/en\">ENG</a>";
     
      
    }
    else {
      this.cambiaLingua = "<a href=\"/it\">ITA</a>";
      
    }
  }
}


