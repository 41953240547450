import { Component, OnInit } from '@angular/core';
import AOS from 'node_modules/aos';


import slick from 'slick-carousel';
declare var $: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ing:boolean =false;
  
  ngOnInit(): void {

    var titolon = document.getElementById("titolon") as HTMLElement;
    
    //var lingua = "ricette";

    if (titolon.innerHTML == "OUR"){
      this.ing = true;
      
      
    }

    window.scrollTo(0, 0);
   
    AOS.init({
      duration: 3000
    });

    var menu = document.getElementById('check') as HTMLInputElement;
    if (menu.checked == true) {
      menu.checked = false;
    }



    

    $('.slider-home').slick({
      slidesToShow: 3,

		slidesToScroll: 1,

		arrows: false,

		fade: false,

    autoplay:true,
    
    centerMode:true,

    responsive: [
  
      {

        breakpoint: 1024,

        settings: {

          slidesToShow: 1,

          slidesToScroll: 1,

          infinite: true

          

        }

      },

      {

        breakpoint: 600,

        settings: {

          slidesToShow: 1,

          slidesToScroll: 1

        }

      }

  ]
    });
  }

}
