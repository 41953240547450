import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component';
import { MenuComponent } from './menu/menu.component';
import { MaterieprimeComponent } from './materieprime/materieprime.component';
import { LavoraComponent } from './lavora/lavora.component';
import { FisheasyComponent } from './fisheasy/fisheasy.component';
import { EasybarComponent } from './easybar/easybar.component';
import { EasyathomeComponent } from './easyathome/easyathome.component';
import { ContattiComponent } from './contatti/contatti.component';
import { EasyLunchComponent } from './easy-lunch/easy-lunch.component';
import { ProposteComponent } from './proposte/proposte.component';
import { AllergeniComponent } from './allergeni/allergeni.component';



const routes: Routes = [
  { path: '', component: HomepageComponent, data: {animation:'homepage'} },
  { path: 'homepage', component: HomepageComponent, data: {animation:'homepage'} },
  { path: 'menu', component: MenuComponent, data: {animation:'contatti'} },
  { path: 'materie-prime', component: MaterieprimeComponent, data: {animation:'contatti'} },
  { path: 'lavora-con-noi', component: LavoraComponent, data: {animation:'contatti'} },
  { path: 'fisheasy', component: FisheasyComponent, data: {animation:'contatti'} },
  { path: 'easy-bar', component: EasybarComponent, data: {animation:'contatti'} },
  { path: 'easy-at-home', component: EasyathomeComponent, data: {animation:'contatti'} },
  { path: 'easy-lunch', component: EasyLunchComponent, data: {animation:'contatti'} },
  { path: 'proposte-dello-chef', component: ProposteComponent, data: {animation:'contatti'} },
  { path: 'contatti', component: ContattiComponent, data: {animation:'contatti'} },
  { path: 'allergeni', component: AllergeniComponent, data: {animation:'contatti'} },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
