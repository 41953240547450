export class Piatto {

    constructor(
        public id :string,
        public nome :string,
        public desc :string,
        public prezzo :string,
        public descrizione :string,
        public ingredienti :string,
        public allergeni :string,
        public img :string,
        public immagine :string,
        public acceso :string
    ){}
}
