import { Component, OnInit } from '@angular/core';
import AOS from 'node_modules/aos';

@Component({
  selector: 'app-materieprime',
  templateUrl: './materieprime.component.html',
  styleUrls: ['./materieprime.component.css']
})
export class MaterieprimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    window.scrollTo(0, 0);
   
    AOS.init({
      duration: 3000
    });

    var menu = document.getElementById('check') as HTMLInputElement;
    if (menu.checked == true) {
      menu.checked = false;
    }
  }

}
