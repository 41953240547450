<div class="row m-0 p-0 justify-content-center" >
    <div class="col-md-8 row mx-0 p-0 justify-content-center mt-200">
        <div class="col-md-6 gruppetto">
            <img src="assets/img/illustration_easybar.png" class="imm-sov imm-cocktail">
            <img src="assets/img/imm1_easybar.png" class="imm-sov imm-cincin">
        </div>


        <div class="col-md-6 ">
            <h2 class="pag-tit text-center" i18n>COCKTAILS & HAPPY HOURS</h2>
            <div class="lineablu"></div>
            <p i18n>In Fisheasy siamo alla continua ricerca di nuovi sapori e abbinamenti, per questo abbiamo all'interno del
                Bistrò un'isola bar in cui il nostro bartender miscela dei cocktail studiati appositamente in abbinamento
                alle nostre proposte di pesce.</p>
        </div>



        <div class="col-md-6 ">

            <p i18n>In Fisheasy vi proponiamo aperitivi con degustazione, per un happy hour alternativo e raffinato che vi
                porta in riva al mare, durante il tramonto, quando la brezza accarezza la superficie dell'acqua e diventa un
                tutt'uno con l'orizzonte.</p>
        </div>

        <div class="col-md-6 gruppetto">
            <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde">
            <img src="assets/img/imm2_easybar.png" class="imm-sov imm-marti">
        </div>

    </div>


</div>
