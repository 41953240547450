import {Component, OnInit} from '@angular/core';
import {Piatto} from 'src/app/piatto';
import AOS from 'node_modules/aos';
import {Drink} from '../drink';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {


  constructor() {
  }

  inglese: boolean;


  antipasti = [
    new Piatto(
      'anti1',
      'COZZE SU PUREA DI ZUCCA',
      'Purea di zucca, cozze sgusciate, con spolverata di rapa rossa',
      '€ 7.50',
      'Un dolce incontro tra la zucca mantovana e la cozza dei nostri mari.',
      '<span>Zucca:</span> proprietà antiossidanti e antiinfiammatorie, calcio, potassio,sodio,magnesio,fosforo e vitamina E. <br/><br/><span>Cozze:</span> ricche di vitamine e sali minerali, ma riservano anche un grande apporto di antiossidanti.',
      'molluschi, sedano',
      'antipasti1_cozzezucca.png',
      'antipasti1_cozzezucca3.jpg',
      '3'
    ),

    new Piatto(
      'anti2',
      'ALICI E ASPARAGI IN AGRODOLCE',
      'Alici fresche, marinate con Asparagi in Agrodolce con crostini di pane croccante e burro al lime',
      '€ 8.00',
      'Alici fresche, marinate con Asparagi in Agrodolce con crostini di pane croccante e burro al lime',
      '',
      'cereali, pesce',
      'Aliciasp.jpeg',
      'Aliciasp.jpeg',
      '3'
    ),

    new Piatto(
      'anti3',
      'ARROSTICINI DI MARE',
      'Spiedini di Totano/seppia, gamberi, grigliati al forno con verdure fresche',
      '€ 12.50',
      'In tre bocconi tutta la freschezza dei sapori mediterranei',
      '<span>Totano:</span> è una fonte di potassio, vitamine e minerali dall’azione antiossidante e di calcio, fosforo e magnesio',
      'pesce, uova, cereali, crostacei, sedano, sesamo',
      'antipasti5_arrosticinidimare.png',
      'antipasti5_arrosticinidimare2.png',
      '3'
    ),

    new Piatto(
      'anti4',
      'SELEZIONE GRATINATI ',
      'Cappesante, Canolicchi, Fasolari e Cozze in delicato gratinato',
      '€ 13.50',
      'Una selezione di conquillage arricchita da un delicato gratinato.',
      '<span>Cannelli:</span> ricchissimi di amminoacidi essenziali, sali minerali (sodio, iodio ecc) e vitamine idrosolubili. <br/><br/><span>Capesante:</span> ricche di proteine di qualità elevata, magnesio, vitamine del gruppo B, fosforo e selenio. <br/><br/><span>Fasolari:</span>proteine, magnesio, zinco e ferro. <br/><br/><span>Cozze:</span> ricche di vitamine e sali minerali, ma riservano anche un grande apporto di antiossidanti.',
      'cereali, molluschi',
      'antipasti6_selezionegratinati.png',
      'antipasti6_selezionegratinati2.png',
      '3'
    ),

    new Piatto(
      'anti7',
      'RANA PESCATRICE CON PANZANELLA O CAROTE E ZENZERO',
      'Coda di Rana Pescatrice su panzanella di pane e verdure o su purea calda di carote e zenzero',
      '€ 10.50-11.00',
      'Coda di Rana Pescatrice su panzanella di pane e verdure o su purea calda di carote e zenzero',
      '',
      'cereali, pesce, sedano',
      'antipasti9_ranapescatrice.png',
      'Ranacaroteezenzero.jpg',
      '3'
    ),
    new Piatto(
      'anti8',
      'GUAZZETTO DI VONGOLE e MAZZANCOLLE',
      'Guazzetto di vongole e mazzancolle intere.',
      '€ 11.00',
      'Le più saporite tra le vongole abbracciano dolci mazzancolle…sporcarsi le dita è d’obbligo.',
      '<span>Lupini:</span> molte proteine e i minerali fondamentali per l’organismo, come il sodio, il fosforo, il selenio, il potassio, il ferro e il calcio. <br/><br/><span>Gamberi:</span> ricchi di fosforo, calcio, sodio, potassio, ferro, selenio, zinco e iodio. Per quel che concerne le vitamine, questi alimenti contengono livelli notevoli di tiamina (B1), riboflavina (B2), niacina (PP) e retinolo equivalenti (pro vitamina A).',
      'molluschi, crostacei, cereali',
      'antipasti8_soute.png',
      'antipasti8_soute2.png',
      '3'
    ),
    new Piatto(
      'anti9',
      'LE TRE TERRE',
      'TRIS DI TERRINE: Alici e verdure, Polpo e patate, Gamberi e zucchine',
      '€ 12.50',
      'Un viaggio tra i sapori e i profumi dei mostri mari.',
      '<span>Alici:</span> ricche di acidi grassi polinsaturi Omega-3, dalle note qualità benefiche per l’organismo.<br/><br/><span>Polpo:</span> fornisce inoltre un valido apporto di calcio, potassio, fosforo, vitamine A e B1. <br/><br/><span>Gamberi:</span> ricchi di fosforo, calcio, sodio, potassio, ferro, selenio, zinco e iodio. Per quel che concerne le vitamine, questi alimenti contengono livelli notevoli di tiamina (B1), riboflavina (B2), niacina (PP) e retinolo equivalenti (pro vitamina A).',
      'crostacei, pesce, sedano, semi di sesamo',
      'antipasti7_letreterre.png',
      'antipasti7_letreterre2.png',
      '3'
    ),
    new Piatto(
      'anti10',
      'TRIS DI MARINATI DELLO CHEF ',
      'Selezione di Marinati dello Chef (Salmone, Spada, Alici, Muggine,Orata, Spigola, con verdure ghiaccio e guarnizione di frutti di bosco',
      '€ 10.00',
      'Selezione di Marinati dello Chef (Salmone, Spada, Alici, Muggine,Orata, Spigola, con verdure ghiaccio e guarnizione di frutti di bosco',
      '',
      'pesce, sedano',
      'antipasti8_trismarinatiazzurri.png',
      'antipasti8_trismarinatiazzurri2.png',
      '3'
    ),
    new Piatto(
      'anti11',
      'SPADA TONNO E MELONE ',
      'Melone con Spada e Tonno marinati ai semi di Zucca',
      '€ 11.00',
      'La dolce freschezza del melone, giallo o retato, insieme alla sapidità di tonno e spada marinati, un agrodolce al naturale',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.<br/><br/><span>Pesce spada:</span> è un concentrato di vitamine e sali minerali. In particolare, la vitamina C, D ed il calcio.',
      'pesce, frutta a guscio, sedano, semi di sesamo',
      'antipasti14_spadatonnomelone.png',
      'antipasti13_spadatonnomelone2.png',
      '3'
    ),
    new Piatto(
      'anti12',
      'SPADA E PAPPA',
      'Spada marinato su tortino di pappa al pomodoro e olive taggiasche',
      '€ 9.00',
      'Spada marinato su tortino di pappa al pomodoro e olive taggiasche',
      '',
      'cereali, pesce',
      'Spadaepappa2.jpg',
      'Spadaepappa2.jpg',
      '3'
    ),
    new Piatto(
      'anti13',
      'SEPPIE E PISELLI',
      'Seppie e piselli con sugo al pomodoro ',
      '€ 9.50',
      'Un piatto intramontabile della tradizione marinara, con pane croccante.',
      '<span>Seppie:</span> ricche di vitamine A, D, B1 e B12, i minerali come selenio, fosforo, potassio, ferro, rame e zinco, la fibra e le purine.',
      'molluschi',
      'antipasti4_seppieepiselli.png',
      'antipasti4_seppieepiselli2.png',
      '3'
    ),
    new Piatto(
      'anti14',
      'GRAN TOUR DI ANTIPASTI CALDI E FREDDI',
      '4 antipasti: 1.Cozze su purea di zucca, 2. Le tre terre, 3. Arrosticini di mare, 4.Selezione di marinati dello Chef',
      '€ 38.00',
      '',
      '4 antipasti: <ul style="list-style-type: none"> <li style="list-style-type: none"> 1.Cozze su purea di zucca </li> <li style="list-style-type: none"> 2. Le tre terre </li> <li style="list-style-type: none"> 3. Arrosticini di mare </li> <li style="list-style-type: none"> 4.Selezione di marinati dello Chef </li> </ul>',
      '(far riferimento agli allergeni delle singole portate)',
      'grantour.png',
      'grantour.png',
      '3'
    ),
    new Piatto(
      'anti14',
      'LUMACHINE DI MARE IN PORCHETTA',
      'Cuccioletti dell\'Adriatico alfinocchietto selvatico e guanciale',
      '€ 11.00',
      'Cuccioletti dell\'Adriatico alfinocchietto selvatico e guanciale',
      '',
      'molluschi',
      'lumachineinporchetta.jpg',
      'lumachineinporchetta.jpg',
      '3'
    ),
    new Piatto(
      'anti14',
      'RAGUSE IN UMIDO',
      'Lumache di mare stufate al pomodoro aglio e cipolla di Cannara',
      '€ 8,50',
      'Lumache di mare stufate al pomodoro aglio e cipolla di Cannara',
      '',
      'molluschi',
      'Raguse.JPG',
      'Raguse.JPG',
      '3'
    ),
    new Piatto(
      'anti15',
      'OSTRICHE A e PREMINUM',
      'Ostriche vive servite con pinzimonio, sale olio pepe e limone/tabasco',
      '€ 3.50-4.50 <span>al pezzo</span>',
      'Con Champagne o Gran Cru…',
      '<span>Ostrica:</span> proteine, sali minerali (zinco, calcio, sodio, potassio, fosforo, ferro) e vitamine del gruppo B',
      'molluschi, sedano',
      'ostriche_premium.png',
      'ostriche_premium.png',
      '3'
    ),
    new Piatto(
      'anti16',
      'SOUTE DI COZZE ALLA MARINARA',
      'Soutè di cozze aperte al momento con olio, aglio e prezzemolo',
      '€ 9.50 <span>al pezzo</span>',
      'Soutè di cozze aperte al momento con olio, aglio e prezzemol',
      '',
      'cereali, uova, pesce, molluschi',
      'SoutèCozze3.jpg',
      'SoutèCozze3.jpg',
      '3'
    ),
    new Piatto(
      'anti17',
      'CARPACCIO DI TONNO',
      'Carpaccio di Tonno marinato al sesamo con insalata',
      '€ 12,00',
      'Una tira l’altra….',
      '',
      'cereali, uova, pesce',
      'Carpacciotonno1.jpg',
      'Carpacciotonno1.jpg',
      '3'
    ),
    new Piatto(
      'anti18',
      'CARPACCIO DI SPADA',
      'Carpaccio di pesce Spada marinato ai semi di papavero con insalata',
      '€ 10,00',
      '',
      '',
      'cereali, crostacei, uova, latte',
      'Carpacciospada.jpg',
      'Carpacciospada.jpg',
      '3'
    ),
  ];

  tranci = [

    new Piatto(
      'tranci1',
      'GRAN FILETTO DI BRANZINO 280 g',
      'GranFiletto di Branzino su misticanza di insalatea l\'arancio e ravanelli',
      '€ 25,00',
      'GranFiletto di Branzino su misticanza di insalatea l\'arancio e ravanelli',
      '',
      'pesce',
      'G.FilettodiBranzino.jpg',
      'G.FilettodiBranzino.jpg',
      '13'
    ),
    new Piatto(
      'tranci2',
      'GRAN FILETTO DI ORATA 280 g',
      'Gran Filetto di Orata caponatina di verdure, julienne di insalata radicchio e carote',
      '€ 20,00',
      'Una proposta senza grassi, dove l’orata è la protagonista',
      '<span>Orata:</span> contiene proteine, vitamine, sali minerali (calcio, fosforo, iodio, ferro) ed è ricca di acidi grassi omega 3.',
      'pesce, sedano',
      'granfiletto_orata.png',
      'granfiletto_orata2.png',
      '13'
    ),
    new Piatto(
      'tranci3',
      'CODA DI PESCATRICE AI CARCIOFI 110 g',
      'Coda di Rana Pescatrice grigliata, carciofi e funghi',
      '€ 14,00',
      'Il sapore aromatico e intenso del carciofo incontra la delicatezza e sapidità della rana pescatrice.',
      '<span>Rana pescatrice:</span> riccha di proteine ad alto valore biologico, vitamine (D e molte vitamine del gruppo B) e minerali specifici, soprattutto fosforo e iodio.',
      'pesce',
      'pescatrice_ai_carciofi.jpg',
      'pescatrice_ai_carciofi.jpg',
      '13'
    ),

    //pescatrice_ai_carciofi.jpg
    new Piatto(
      'tranci4',
      'GRAN FILETTO DI MUGGINE 280 g',
      'Filetto di Muggine con valeriana, carote ghiaccio, ravanello, cappuccio viola',
      '€ 16,50',
      'Un pesce particolarmente ricco di omega 3, al naturale, per apprezzarne anche il gusto deciso e intenso.',
      '<span>Muggine:</span> contiene una buona quantità di Omega 3, ma anche di sali minerali come fosforo, magnesio, calcio e vitamina B6',
      'pesce',
      'GFilettodiMuggine.jpg',
      'GFilettodiMuggine.jpg',
      '13'
    ),
    new Piatto(
      'tranci5',
      'SPIGOLA IN CREMA DI PISELLI 110g',
      'Filetto di spigola in crema di piselli cotti in brodo vegetale',
      '€ 11,50',
      'Filetto di spigola in crema di piselli cotti in brodo vegetale',
      '',
      'pesce',
      'filetti_turbantedispigola.png',
      'filetti_turbantedispigola2.png',
      '13'
    ),
    new Piatto(
      'tranci6',
      'ORATA AGLI ORTAGGI 180g',
      'Orata con dadolata di verdure di stagione',
      '€ 13,50',
      'L’Orata ai profumi dell’orto in primavera.',
      '<span>Orata:</span> contiene proteine, vitamine, sali minerali (calcio, fosforo, iodio, ferro) ed è ricca di acidi grassi omega 3.',
      'pesce, sedano',
      'filetto_orataortaggi.png',
      'filetto_orataortaggi2.png',
      '13'
    ),
    new Piatto(
      'tranci7',
      'TONNO AI PISTACCHI DI BRONTE 180g',
      'Tonno ricoperto di granella di pistacchi, con valeriana e cipolla caramellata',
      '€ 24,00',
      'Buono, buono, buono, con basso contenuto di grassi e tutte le proteine nobili del Tonno.',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.',
      'pesce, frutta a guscio',
      'Tonnoaipist.diBronte.jpg',
      'Tonnoaipist.diBronte.jpg',
      '13'
    ),
    new Piatto(
      'tranci8',
      'TAGLIATA DI TONNO AGLIO E ROSMARINO 180g',
      'Tagliata di tonno aglio e rosmarino con spinaci e insalatina di finocchi e noci',
      '€ 23,00',
      'Tagliata di tonno aglio e rosmarino con spinaci e insalatina di finocchi e noci',
      '',
      'pesce',
      'Tagliatatonnoagliorosmarino.jpg',
      'Tagliatatonnoagliorosmarino.jpg',
      '13'
    ),
    new Piatto(
      'tranci9',
      'TONNO IN AVOCADO 180g',
      'Tagliata di tonno grigliato con verdure saltate e Avocado',
      '€ 25,00',
      'Un piatto ricco di sorprese, da scoprire una forchettata dietro l’altra.',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.',
      'pesce, sedano',
      'trancio_tonnoavocado.png',
      'trancio_tonnoavocado2.png',
      '13'
    ),
    new Piatto(
      'tranci10',
      'PESCE SPADA SCAROLA E PINOLI 180g',
      'Trancio di spada su scarola e pinoli, con insalata in agrodolce',
      '€ 18,50',
      'In Sicilia è un classico da secoli…. Assaggiatelo.',
      '<span>Pesce spada:</span> è un concentrato di vitamine e sali minerali. In particolare, la vitamina C, D ed il calcio.',
      'pesce, frutta a guscio',
      'trancio_pescespadascarolapinoli.png',
      'trancio_pescespadascarolapinoli2.png',
      '13'
    ),
    new Piatto(
      'tranci11',
      'FILETTO DI PERSICO E LENTICCHIE 120g',
      'Filetto di Persico, lenticchia di Castelluccio e cipolla di Cannara, l\'Umbria in un piatto',
      '€ 10,50',
      'Da Castelluccio al Trasimeno, passando per Cannara, l’Umbria concentrata in un piatto.',
      '<span>Pesce persico:</span> ricco di sali minerali e vitamine PP e D.',
      'pesce',
      'filetto_persicoconlenticchie.png',
      'filetto_persicoconlenticchie3.jpg',
      '13'
    ),
    new Piatto(
      'tranci12',
      'SALMONE E PATATA ROSSA 180g',
      'Filetto di salmone al forno con patate rosse di Colfiorito e asparagi',
      '€ 15,00',
      'Tutto il buono delle patate di Colfiorito con le proteine nobili del salmone.',
      '<span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12.',
      'cereali, uova, pesce',
      'filetto_salmonepatatarossa.png',
      'filetto_salmonepatatarossa2.png',
      '13'
    ),
    new Piatto(
      'tranci13',
      'MUGGINE TAGGIASCHE E CAPPERO 180g',
      'Trancio di muggine con pomodorini al basilico, olive e fior di cappero',
      '€ 13,00',
      'Un concentrato di sapori mediterranei, dal nord al sud.',
      '<span>Muggine:</span> contiene una buona quantità di Omega 3, ma anche di sali minerali come fosforo, magnesio, calcio e vitamina B6.',
      'pesce',
      'R6__8283-OK FGo8.jpg',
      'R6__8283-OK FGo8.jpg',
      '13'
    ),
    new Piatto(
      'tranci14',
      'SPINAROLO CAPPERI E OLIVE 180g',
      'Spinarolo (Palombo) fresco in salsa di pomodoro, capperi e olive',
      '€ 12,50',
      'Spinarolo (Palombo) fresco in salsa di pomodoro, capperi e olive',
      '',
      'pesce',
      'Spinarolocapperi.jpg',
      'Spinarolocapperi.jpg',
      '13'
    ),
    new Piatto(
      'tranci15',
      'POLPO VERACE ARROSTO CON PURE\' DI PATATE 90g',
      'Tentacoli di polpo grigliati con purè di patate e olio EVO',
      '€ 13,50',
      'Tentacoli di polpo grigliati con purè di patate e olio EVO',
      '',
      'latte, molluschi',
      'polpoepurè.jpg',
      'polpoepurè.jpg',
      '13'
    ),
    new Piatto(
      'tranci16',
      'TONNO, SALMONE E SPADA AGLI AGRUMI 240g',
      'Tranci di tonno, salmone e spada con spinaci, tortino di agrumi e frutti bosco',
      '€ 25,00',
      'Spinaci appena scottati e pompelmo a vivo: un agro-dolce da gustare.',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo. <br/><br/><span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12.<br/><br/><span>Pesce spada:</span>è un concentrato di vitamine e sali minerali. In particolare, la vitamina C, D ed il calcio.',
      'pesce',
      'tris_agliagrumi.png',
      'tris_agliagrumi3.jpg',
      '13'
    ),
    new Piatto(
      'tranci17',
      'TRIS DI GRIGLIATI BIANCHI 240g',
      'Orata, Spigola e Persico grigliati con caponata di verdure fresche e insalatina',
      '€ 18,00',
      'Il bianco e il nero, pochi secondi di cottura per il massimo della fragranza.',
      '<span>Orata:</span> contiene proteine, vitamine, sali minerali (calcio, fosforo, iodio, ferro) ed è ricca di acidi grassi omega 3. <br/><br/><span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo. <br/><br/><span>Pesce persico:</span> ricco di sali minerali e vitamine PP e D.',
      'pesce, sedano',
      'TRIS_grigliati_bianchi.jpg',
      'TRIS_grigliati_bianchi.jpg',
      '13'
    ),
    new Piatto(
      'tranci18',
      'BRANZINO IN GRATICOLA 180g',
      'Filetti di spigola con rosti di patate, valeriana al pompelmo e radicchio',
      '€ 16,00',
      'Una cottura breve e senza grassi, con una croccante sorpresa di patate.',
      '<span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo.',
      'pesce',
      'trancio_branzinoingraticola.png',
      'trancio_branzinoingraticola3.jpg',
      '13'
    ),
    // Da mostrare su menu, ma manca immagine
    new Piatto(
      'tranci19',
      'BACCALA AI PROFUMI DEL CONERO 160g',
      'Filetti di Baccalà patata rossa, olive nere e pomodoro',
      '€ 16,00',
      'Filetti di Baccalà patata rossa, olive nere e pomodoro',
      '',
      'pesce',
      'Baccalà.jpg',
      'Baccalà.jpg',
      '13'
    )

  ];

  primi = [
    new Piatto(
      'prim1',
      'MACCHERONCINI DI CAMPOFILONE SEPPIE E PISELLI',
      'Un sugo tra i più classici della cucina marinara con l’unicità della pasta di Campofilone, un connubio di sapori.',
      '€ 12.50',
      'Un sugo tra i più classici della cucina marinara con l’unicità della pasta di Campofilone, un connubio di sapori.',
      '<span>Seppie:</span> ricche di vitamine A, D, B1 e B12, i minerali come selenio, fosforo, potassio, ferro, rame e zinco.',
      'molluschi, sedano, derivati uova',
      'maccheroncini_seppiepiselli1.png',
      'maccheroncini_seppiepiselli2.jpg',
      '0'
    ),
    new Piatto(
      'prim2',
      'CHICCHE DI PATATE VONGOLE E BOTTARGA',
      'Chicche di patate, vongole lupino fresche con una spolverata di bottarga.',
      '€ 10.50',
      'Chicche di patate, vongole lupino fresche con una spolverata di bottarga.',
      '<span>Vongole:</span> una buona quantità di cobalamina (vitamina B12), indispensabile per la formazione del sangue e nel metabolismo delle proteine e dei grassi; ricca di sali minerali come fosforo, potassio e iodio, zinco, selenio.',
      'molluschi',
      'gnocchi_vongole1.png',
      'gnocchi_vongole2.png',
      '0'
    ),
    new Piatto(
      'prim4',
      'TAGLIATELLE AGLI SGOMBRI',
      'Sgombro, pomodoro ciliegino e cipolla dolce di Cannara',
      '€ 13.00',
      'Sgombro, pomodoro ciliegino e cipolla dolce di Cannara',
      '',
      'Pesce, uova e derivati, prodotti a base di latte.',
      'Tagliatelleaglisgombri.jpg',
      'Tagliatelleaglisgombri.jpg',
      '0'
    ),
    new Piatto(
      'prim4',
      'LASAGNA BIANCA ORATA E SALMONE',
      'Pasta sfoglia fresca all’uovo con orata e spigola leggermente scottate, in besciamella',
      '€ 12.00',
      'Pasta sfoglia fresca all’uovo con orata e spigola leggermente scottate, in besciamella',
      '<span>Orata:</span> contiene proteine, vitamine, sali minerali (calcio, fosforo, iodio, ferro) ed è ricca di acidi grassi omega 3. <br><span>Salmone:</span> ricco di acidi grassi polinsaturi Omega‐3, vitamine A, D e B12.',
      'Pesce, uova e derivati, prodotti a base di latte.',
      'lasagna_bianca1.png',
      'lasagna_bianca1.png',
      '0'
    ),
    new Piatto(
      'prim4',
      'FETTUCCINE MARI E MONTI ',
      'Fettuccine rustiche con brodetto di Rana pescatrice e Gallinella e funghi champignon',
      '€ 11.00',
      'Fettuccine rustiche con brodetto di Rana pescatrice e Gallinella e funghi champignon',
      '',
      'Pesce, uova e derivati, prodotti a base di latte.',
      'Fettuccinemari-Monti.jpg',
      'Fettuccinemari-Monti.jpg',
      '0'
    ),
    new Piatto(
      'prim4',
      'GNOCCHETTI ALLE CANOCCHIE',
      'Gnocchetti di patate pomodoro fresco e cicale di mare',
      '€ 12.50',
      'Gnocchetti di patate pomodoro fresco e cicale di mare',
      '',
      'Pesce, uova e derivati, prodotti a base di latte.',
      'Gnocchetticanocchie.jpeg',
      'Gnocchetticanocchie.jpeg',
      '0'
    )


  ];

  burger = [
    new Piatto(
      'burg1',
      'FISHBURGER BIANCO 130G',
      'FBurger di Muggine lattuga, pomodoro, noci tritate e salsaguacamole.',
      '€ 10.50',
      'Connubio di colori e sapori del lago in un morso.',
      '<span>Pesce persico:</span> ricco di sali minerali e vitamine PP e D. <br/><br/><span>trota:</span> ricca di sali minerali, ferro e potassio e scarso apporto di sodio.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova, latte',
      'fishburger_bianco.png',
      'fishburger_bianco3.jpg',
      '2'
    ),

    new Piatto(
      'burg2',
      'FISHBURGER AZZURRO 130G',
      'Muggine, indivia belga, maionese, insalata gentile, pomodoro, noci tritate e guacamole',
      '€ 10.50',
      'Il sapore deciso della muggine, le sue proteine nobili, arricchita con ortaggi freschi.',
      '<span>Muggine:</span> contiene una buona quantità di Omega 3, ma anche di sali minerali come fosforo, magnesio, calcio e vitamina B6.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova, latte',
      'fishburger_azzurro.png',
      'fishburger_azzurro3.jpg',
      '13'
    ),
    new Piatto(
      'burg3',
      'FISHBURGER DELUXE TONNO 130G',
      'Fishburger mix di Spigola, Spada e Tonno radicchio con senape in grani, maionese, granella di pistacchi',
      '€ 14.00',
      'Un mix di pesce nobile in un boccone, con una sensazione di dolce amaro ad accompagnarlo.',
      '<span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo. <br/><br/><span>Pesce spada:</span> è un concentrato di vitamine e sali minerali. In particolare, la vitamina C, D ed il calcio. <br/><br/><span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova, latte',
      'fishburger_deluxetonno.png',
      'fishburger_deluxetonno3.jpg',
      '23'
    ),
    new Piatto(
      'burg4',
      'FISHBURGER DELUXE AL SALMONE 130G',
      'Fishburger mix di Spigola, spada e salmone, guacamole, carote, ( 1,3,4,7,11) insalata ,radicchio lungo al forno, maionese.',
      '€ 12.00',
      'Una combinazione che lascia al palato una dolce sensazione.',
      '<span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo. <br/><br/><span>Pesce spada:</span>  è un concentrato di vitamine e sali minerali. In particolare, la vitamina C, D ed il calcio.<br/><br/><span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova, latte',
      'fishburger_deluxesalmone.png',
      'fishburger_deluxesalmone3.jpg',
      '3'
    ),
    // Da aggiungere al menu, manca l'immagine
    new Piatto(
      'burg5',
      'FISHBURGER PERSICO E SALMONE 130g',
      'Con insalata, guacamole, pomodoro verde, Melanzane grigliate',
      '€ 12.00',
      'Gustoso Succulento con un anima esotica.',
      '<span>Pesce persico:</span> ricco di sali minerali e vitamine PP e D.<br/><br/><span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova, latte',
      'FB_Persico_e_salmone.jpg',
      'FB_Persico_e_salmone.jpg',
      '3'
    ),

    new Piatto(
      'burg5',
      'TRIS DI PANINI AI MARINATI 120g',
      'Selezione di marinati dello Chef tra Alici, Muggine, Spada, Orata, Spigola, Salmone su Buns artigianali',
      '€ 13.00',
      'Selezione di marinati dello Chef tra Alici, Muggine, Spada, Orata, Spigola, Salmone su Buns artigianali',
      '',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova, latte',
      'Trispaninimarinati.jpg',
      'Trispaninimarinati.jpg',
      '3'
    ),
    new Piatto(
      'burg7',
      'NASELLO STICK (1 HG)',
      '1 filetto di nasello panato',
      '€ 10.50 1 PEZZO',
      'Solo nasello battuto al coltello, senza altri “intermediari”, croccanti patate e verdure lo completano.',
      '<span>Nasello:</span> ricco proteine, omega 3 e sali minerali come potassio, ferro, calcio e fosforo.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova',
      'stick_nasello.png',
      'stick_nasello3.jpg',
      '3'
    ),
    new Piatto(
      'burg8',
      'NASELLO STICK (2 HG)',
      '2 filetti di nasello panati',
      '€ 18.00 2 PEZZI',
      'Solo nasello battuto al coltello, senza altri “intermediari”, croccanti patate e verdure lo completano.',
      '<span>Nasello:</span> ricco proteine, omega 3 e sali minerali come potassio, ferro, calcio e fosforo.',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova',
      'stick_nasello2.png',
      'stick_nasello2_3.jpg',
      '3'
    ),
    new Piatto(
      'burg9',
      'FISH AND CHIPS 120g',
      'Filetto di spigola panato con patatine chips e insalatina di valeriana ai semi croccanti',
      '€ 14.00',
      'Una panatura non fritta, nel massimo rispetto del pesce al suo interno.',
      '<span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo',
      'pesce, sedano, senape, sesamo, frutta a guscio, cereali, glutine, uova',
      'stick_fishandchips.png',
      'stick_fishandchips3.jpg',
      '23'
    ),
    new Piatto(
      'burg10',
      'GRAN FRITTO MISTO',
      'Pesce spinato, frutti di mare, verdure e crostacei in delicata tempura',
      '€ 14.50',
      'Pesce spinato, frutti di mare e crostacei, tutti insieme come nella rete del pescatore,  in delicata tempura con qualche veg che nei nostri piatti non manca mai. Un’estasi “papillogustativa”...',
      '<span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo <br><br><span>Orata:</span> contiene proteine, vitamine, sali minerali (calcio, fosforo, iodio, ferro) ed è ricca di acidi grassi omega 3.<br><br><span>Pesce Persico:</span> ricco di sali minerali e vitamine PP e D.<br><br><span>Mazzancolle:</span> ricche di fosforo, calcio, sodio, potassio, ferro, selenio, zinco e iodio. Per quel che concerne le vitamine, questi alimenti contengono livelli notevoli di tiamina (B1), riboflavina (B2), niacina (PP) e retinolo equivalenti (pro vitamina A).<br><br><span>Cannelli:</span> ricchissimi di amminoacidi essenziali, sali minerali (sodio, iodio ecc) e vitamine idrosolubili.',
      'pesce, crostacei, molluschi',
      'fritto_misto.png',
      'fritto_misto2.jpg',
      '0'
    ),
  ];

  salad = [
    new Piatto(
      'salad5',
      'Insalata di mare',
      'Totani e gamberi al vapore in verdure ghiaccio',
      '€ 11.00',
      'Totani e gamberi al vapore in verdure ghiaccio',
      '',
      'crostacei, frutta a guscio, sedano, semi di sesamo, molluschi',
      'insalata_di_mare.jpg',
      'insalata_di_mare.jpg',
      '3'
    ),

    // da cancellare forse
    new Piatto(
      'salad5',
      'TUNAFISH SALAD (1-2HG)',
      'Tonno scottato con sesamo, rucola, pomodorino datterini, iceberg, carote, valeriana',
      '€ 12.50',
      'Tre insalatine semplici e sfiziose, per uno spuntino leggero, nutriente a zero grassi saturi..',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.',
      'pesce, sedano, senape, sesamo, frutta a guscio',
      'tuna_fishsalad.png',
      'tuna_fishsalad3.jpg',
      '1'
    ),
    new Piatto(
      'salad6',
      'TUNAFISH SALAD (1-2HG)',
      'Tonno grigliato con pomodoro, insalata mista, sesamo, carote',
      '€ 14,00/22,00',
      'Tre insalatine semplici e sfiziose, per uno spuntino leggero, nutriente a zero grassi saturi..',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.',
      'pesce, sedano, senape, sesamo, frutta a guscio',
      'tuna_fishsalad.png',
      'tuna_fishsalad3.jpg',
      '3'
    ),
    new Piatto(
      'salad7',
      'INSALATA FANTASIA – TONNO 140g',
      'Tonno, misticanza di insalata, sesamo, scaglie di mandorla, patate, avocado, frutti di bosco',
      '€ 22,00',
      'Il tonno con i sapori della terra e un tocco di frutta esotica.',
      '<span>Tonno:</span> contiene un notevole quantitativo di proteine ad alto valore biologico e di grassi essenziali e omega 3; è anche ricco di vitamine: tiamina, riboflavina, niacina e retinolo.',
      'pesce, sedano, sesamo, frutta a guscio',
      'insalatafantasia_tonno.png',
      'insalatafantasia_tonno3.jpg',
      '3'
    ),
    new Piatto(
      'salad8',
      'INSALATA FANTASIA – SALMONE 140g',
      'Salmone grigliato, olive taggiasche, valeriana,ananas, cappuccio viola,ribes e tzatziki',
      '€ 15.00',
      'L’esotico, i profumi della liguria e quelli ionici insaporiscono il Re dei pesci nordici.',
      '<span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12.',
      'pesce, sesamo ',
      'insalatafantasia_salmone.png',
      'insalatafantasia_salmone3.jpg',
      '3'
    ),
    new Piatto(
      'salad10',
      'INSALATA FANTASIA – SPADA 140G',
      'Spada con carote julienne, noci, pompelmo, cipolla agro, musticanza di insalata, mela verde a sfere, con ciuffo di cavolo viola',
      '€ 16.00',
      'La mela racchiude in se tutto il sapore del frutto, per una sensazione d’insieme di sicuro piacere.',
      '<span>Pesce spada:</span> è un concentrato di vitamine e sali minerali. In particolare, la vitamina C, D ed il calcio.',
      'pesce, sedano, sesamo, frutta a guscio',
      'insalatafantasia_spada.png',
      'insalatafantasia_spada2.png',
      '3'
    ),
    new Piatto(
      'salad11',
      'INSALATA DI SALMONE MARINATO',
      'Fette di salmone marinato con insalata Valeriana, avocado in pezzi, pomodorini ciliegino, ananas, sedano',
      '€ 12.00',
      'Un sapore unico avvolgente ed esotico.',
      '<span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12.',
      'pesce, sedano',
      'insalatafantasia_salmonemarinato.png',
      'insalatafantasia_salmonemarinato3.jpg',
      '3'
    ),
    new Piatto(
      'salad12',
      'SALMONFISH SALAD (1-2HG)',
      'Cappuccio viola, finocchio ghiaccio, pomodoro ciliegino, insalata gentile, valeriana',
      '€ 10.00/17.00',
      'Tre insalatine semplici e sfiziose, per uno spuntino leggero, nutriente a zero grassi saturi.',
      '<span>Salmone:</span> ricco di acidi grassi polinsaturi Omega-3, vitamine A, D e B12. ',
      'pesce, sedano, sesamo, frutta a guscio',
      'salmon_fishsalad.png',
      'salmon_fishsalad3.jpg',
      '3'
    ),

  ];

  dolci = [
    /* new Piatto(
       'dolci1',
       'IL CAFFÈ ALLA MOKA LO OFFRIAMO NOI',
       '',
       '',
       '',
       '',
       'grano, mandorle, uova, latte',
       'salmon_fishsalad.png',
       'salmon_fishsalad3.jpg',
       '1'
     ),*/
    new Piatto(
      'dolci2',
      'CROSTATINA FISHEASY',
      'Frolla al limone, crema pasticcera, frutti di bosco',
      '€ 7.00',
      'Cestino del sottobosco.',
      '',
      'cereali, latte',
      'crostatina.png',
      'crostatina3.jpg',
      '1'
    ),
    new Piatto(
      'dolci3',
      'CHEESECAKE AI LAMPONI',
      'Formaggio spalmabile, biscotto, gelatine e salsa di lamponi tutto fatto “in-house”',
      '€ 6.50',
      'C’era una volta in America.',
      '',
      'cereali, latte',
      'cheescake.png',
      'cheescake3.jpg',
      '1'
    ),

    new Piatto(
      'dolci4',
      'TIRAMISÙ',
      'Savoiardi, caffe, mascarpone, limone',
      '€ 6.00',
      'Un classico ma non troppo.',
      '',
      'cereali, latte',
      'tiramisu2.png',
      'tiramisu3.jpg',
      '1'
    ),
    new Piatto(
      'dolci5',
      'PANNA COTTA AL PASSION FRUIT',
      'Panna cotta gelato al frutto della passione',
      '€ 5.50',
      'Panna cotta gelato al frutto della passione',
      '',
      'latte',
      'Pannacottaalpassionfruit.jpg',
      'Pannacottaalpassionfruit.jpg',
      '1'
    ),
    new Piatto(
      'dolci6',
      'PANNA COTTA E PERE ABATE',
      'Panna cotta gelato con tagliata di pere cotte in riduzione di vino bianco',
      '€ 5.50',
      'Panna cotta gelato con tagliata di pere cotte in riduzione di vino bianco',
      '',
      'latte',
      'Pannacottaepereabate.jpg',
      'Pannacottaepereabate.jpg',
      '1'
    ),
    new Piatto(
      'dolci6',
      'FLAN AL CIOCCOLATO FUSO',
      'Tortino al ripieno di cioccolato caldo con panna',
      '€ 6.00',
      'Tortino al ripieno di cioccolato caldo con panna',
      '',
      'cereali, uova, latte',
      'Flan.jpg',
      'Flan.jpg',
      '1'
    ),
    new Piatto(
      'dolci6',
      'SEMIFREDDO AL COCCO E CARAMELLO',
      'Semifreddo al cocco, caramello e scaglie di cioccolato',
      '€ 5.50',
      'Semifreddo al cocco, caramello e scaglie di cioccolato',
      '',
      'cereali, uova, latte',
      'Semifreddococcoecaramello.jpeg',
      'Semifreddococcoecaramello.jpeg',
      '1'
    ),
  ];


  antipastiEn = [
    new Piatto(
      'anti1',
      'MUSSELS ON PUMPKIN PUREE',
      'Shelled mussels on yellow squash(PUREE), onion, sage and rosemary.',
      '€ 9.00',
      'A sweet encounter between the Mantuan pumpkin and the mussel from our seas.',
      '<span>Pumpkin:</span> antioxidant and anti-inflammatory properties, calcium, potassium, sodium, magnesium, phosphorus and E vitamin. <br/><br/><span>Mussels:</span> rich in vitamins and minerals, with a large supply of antioxidants too.',
      'shellfish, celery',
      'antipasti1_cozzezucca.png',
      'antipasti1_cozzezucca3.jpg',
      '3'
    ),

    new Piatto(
      'anti2',
      'MUSSELS TEMPURA',
      'Punpkin cream, tempura mussels with aubergines, courgettes and black squid ink chips',
      '€ 9.00',
      'The crunchiness of the tempura meets the softness of the pumpkin, taking you on a journey of flavours.',

      '<span>Pumpkin:</span> antioxidant and anti-inflammatory properties, calcium, potassium, sodium, magnesium, phosphorus and E vitamin. <br/><br/><span>Mussels:</span> rich in vitamins and minerals, with a large supply of antioxidants too.',

      'cereals, shellfish, egg',
      'antipasti2_cozzetempura.png',
      'antipasti2_cozzetempura3.jpg',
      '1'
    ),

    new Piatto(
      'anti3',
      'SEARED SEA SCALLOPS WITH TZATZIKI',
      'Seared sea scallops with vegetables in tzatziki sauce.',
      '€ 12.00',
      'When the scallop is “contaminated” by Hellenic flavours.',

      '<span>Scallops:</span> rich in high-quality proteins, magnesium, B vitamins, phosphorus and selenium.',

      'shellfish, soy, milk',
      'antipasti3_capesantescottate.png',
      'antipasti3_capesantescottate3.jpg',
      '0'
    ),

    new Piatto(
      'anti4',
      'CUTTLEFISH AND PEAS',
      'Cuttlefish, tomato sauce, peas, oil, salt and pepper',
      '€ 9.50',

      'A timeless dish of the maritime tradition, with crunchy bread.',

      '<span>Cuttlefish:</span> rich in vitamins A, D, B1 and B12, minerals such as selenium, phosphorus, potassium, iron, copper and zinc, fibre and purines.',
      'celery, fish',
      'antipasti4_seppieepiselli.png',
      'antipasti4_seppieepiselli3.jpg',
      '12'
    ),

    new Piatto(
      'anti5',
      'SEA ARROSTICINI',
      'Grilled molluscs and shellfish skewers with vegetables (Zucchini/Eggplant/Peppers)',
      '€ 12.50',

      'In three bites all the freshness of Mediterranean flavours',


      '<span>Squid:</span> it is a source of potassium, vitamins and minerals with antioxidant action and calcium, phosphorus and magnesium',
      'fish, cereals, shellfish, celery, sesame',
      'antipasti5_arrosticinidimare.png',
      'antipasti5_arrosticinidimare3.jpg',
      '23'
    ),

    new Piatto(
      'anti6',
      'GRATINÈE SELECTION',
      'Selection of Scallops, razor clams, large clams and mussels',
      '€ 13.50',
      'A shellfish selection enriched with a delicate gratin.',

      '<span>Razor clams:</span> rich in essential amino acids, mineral salts (sodium, iodine, etc.) and water-soluble vitamins <br/><br/><span>Sea scallops:</span> rich in high-quality proteins, magnesium, B vitamins, phosphorus and selenium. <br/><br/><span>Large clams:</span> proteins, magnesium, zinc and iron. <br/><br/><span>Mussels:</span> rich in vitamins and minerals, with a large supply of antioxidants too.',
      'cereals, shellfish, celery, soy',
      'antipasti6_selezionegratinati.png',
      'antipasti6_selezionegratinati3.jpg',
      '0'
    ),
    new Piatto(
      'anti7',
      'LE TRE TERRE',
      'Terrines of prawns and zucchini, octopus and potatoes, anchovies and veg.ratatouille,cooked with oil, garlic and thyme',
      '€ 12.50',
      ' A journey through the flavours and aromas of our seas.',
      '<span>Anchovies:</span> rich in Omega-3 polyunsaturated fatty acids, with well-known beneficial qualities for the body.<br/><br/><span>Octopus:</span> it provides a valid supply of calcium, potassium, phosphorus, vitamins A and B1. <br/><br/><span>Shrimps:</span> rich in phosphorus, calcium, sodium, potassium, iron, selenium, zinc and iodine. When it comes to vitamins, these foods contain substantial levels of thiamin (B1), riboflavin (B2), niacin (PP) and retinol equivalents (pro A vitamin).',
      'crustaceans, molluscs, cereals, gluten, fish, celery, soy',
      'antipasti7_letreterre.png',
      'antipasti7_letreterre3.jpg',
      '23'
    ),
    new Piatto(
      'anti8',
      'TRIO OF WHITE MARINATES WITH MEDITERRANEAN FLAVOURS',
      'Sea bass, sea bream, swordfish',
      '€ 11.00',
      'Our raw marinated fish, with citrus and all the freshness of wild berries.',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus.<br/><br/><span>Sea bream:</span> it contains proteins, vitamins, mineral salts (calcium, phosphorus, iodine, iron) and it is rich in omega 3 fatty acids. <br/><br/><span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'fish, celery',
      'antipasti8_trismarinatibianchi.png',
      'antipasti8_trismarinatibianchi3.jpg',
      '1'
    ),
    new Piatto(
      'anti9',
      'TRIO OF BLUE MARINATES WITH MEDITERRANEAN FLAVOURS',
      'Salmon, anchovies, mullet',
      '€ 11.00',
      'The scent of flowers meets the sea, in a delicate citrus marinade.',
      '<span>Salmon:</span> ​rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12. <br/><br/><span>Anchovies:</span> rich in Omega-3 polyunsaturated fatty acids, with known beneficial qualities for the organism.<br/><br/><span>Mullet:</span> it contains a good amount of Omega 3, but also mineral salts such as phosphorus, magnesium, calcium and B6 vitamin.',
      'fish, celery',
      'antipasti8_trismarinatiazzurri.png',
      'antipasti8_trismarinatiazzurri3.jpg',
      '3'
    ),
    new Piatto(
      'anti10',
      'CLAMS AND MAZZANCOLLE SOUTÈ ',
      'Stew of clams and whole prawns',
      '€ 10.50',
      'The tastiest clams embrace sweet prawns… getting your fingers dirty is a must.',

      '<span>Sea lupins:</span> they have many proteins and minerals essential for the body, such as sodium, phosphorus, selenium, potassium, iron and calcium <br/><br/><span>Shrimps:</span> rich in phosphorus, calcium, sodium, potassium, iron, selenium, zinc and iodine .When it comes to vitamins, these foods contain substantial levels of thiamin (B1), riboflavin (B2), niacin (PP) and retinol equivalents (pro A vitamin).',
      'molluscs, crustaceans, cereals, flour-based foods',
      'antipasti8_soute.png',
      'antipasti8_soute3.jpg',
      '13'
    ),
    new Piatto(
      'anti11',
      'MONKFISH WITH CARROTS AND GINGER',
      'Monkfish tail with carrot and ginger puree',
      '€ 10.50',
      'The sweet and the sour of ginger and carrots at the service of the white meat of the Monkfish.',
      '<span>Monkfish:</span> rich in high biological value proteins, vitamins (D and many B vitamins) and specific minerals, especially phosphorus and iodine.',
      'fish, celery, flour-based foods, cereals',
      'antipasti9_ranapescatrice.png',
      'antipasti9_ranapescatrice3.jpg',
      '1'
    ),
    new Piatto(
      'anti12',
      'MONKFISH WITH PANZANELLA',
      'Monkfish tail with stale bread panzanella, onion, celery, vinegar, basil, carrot, cherry tomatoes, salt and oil',
      '€ 10.50',
      'When panzanella is not just a poor dish but… it meets the monkfish.',
      '<span>Monkfish:</span> rich in high biological value proteins, vitamins (D and many B vitamins) and specific minerals, especially phosphorus and iodine.',
      'cereals, flour-based foods, celery, fish',
      'antipasti10_ranapescatricepanzanella.png',
      'antipasti10_ranapescatricepanzanella3.jpg',
      '3'
    ),
    new Piatto(
      'anti13',
      'MONKFISH WITH FRESH ARTICHOKES',
      'Monkfish tail served with julienne of cooked and raw artichokes',
      '€ 12.50',
      'The aromatic and intense flavour of the artichoke meets the delicacy and flavour of the monkfish.',
      '<span>Monkfish:</span> rich in high biological value proteins, vitamins (D and many B vitamins) and specific minerals, especially phosphorus and iodine.',
      'fish, cereals, gluten',
      'antipasti11_ranapescatricecarciofi.png',
      'antipasti11_ranapescatricecarciofi3.jpg',
      '2'
    ),
    new Piatto(
      'anti14',
      'GRAN TOUR DI ANTIPASTI',
      '3 Tris + the appetizer is proposed by the Chef',
      '€ 36.00',

      'The Grand Tour of Appetizers includes::',

      '<ul><li>Clams and mazzancolle soutè</li><li>Trio of white marinates</li><li>swordfish tuna and melon</li><li>Monkfish with panzanella</li></ul>',
      'fish, celery, molluscs, shellfish, cereals, flour-based foods, pumpkin seeds',
      'grantour.png',
      'grandtour.jpg',
      '1'
    ),

    new Piatto(
      'anti14',
      'GRAN TOUR DI ANTIPASTI',
      '3 Tris + the appetizer is proposed by the Chef',
      '€ 36.00',

      'The Grand Tour of Appetizers includes::',

      '<ul><li>Cuttlefish and peas</li><li>Gratinèe selection</li><li>Crunchy rice, potatoes and mussels</li><li>Pappa al pomodoro with marinated swordfish</li></ul>',
      'celery, fish, bread, cereals, molluscs, soy',
      'grantour.png',
      'grandtour.jpg',
      '2'
    ),

    new Piatto(
      'anti14',
      'GRAN TOUR DI ANTIPASTI',
      '3 Tris + the appetizer is proposed by the Chef',
      '€ 36.00',

      'The Grand Tour of Appetizers includes::',

      '<ul><li>Mussels on pumpkin puree</li><li>Sea Arrosticini</li><li>Le Tre terre</li><li>Trio of Blue Marinates with Mediterranean Flavours</li></ul>',
      'fish, molluscs, shellfish, celery, cereals, gluten, soy, sesame',
      'grantour.png',
      'grandtour.jpg',
      '3'
    ),
    new Piatto(
      'anti15',
      'OYSTERS',
      '3 Trio + the appetizer proposed by the Chef',
      '€ 3.00 <span>per piece</span>',
      'With Champagne or Gran Cru...',
      '<span>Oyster:</span> they provide proteins, mineral salts (zinc, calcium, sodium, potassium, phosphorus, iron) and B vitamins',
      'shellfish, celery',
      'antipasti12_ostriche.png',
      'antipasti12_ostriche3.jpg',
      '0'
    ),
    new Piatto(
      'anti16',
      'PREMIUM OYSTERS',
      'Ostriche aperte fresche, olio, pepe da grattugiare, tabasco e limone',
      '€ 4.00 <span>per piece</span>',
      'Con Champagne o Gran Cru…',
      '<span>Ostrica:</span> proteine, sali minerali (zinco, calcio, sodio, potassio, fosforo, ferro) e vitamine del gruppo B',
      'molluschi, sedano',
      'ostriche_premium.png',
      'ostriche_premium.png',
      '0'
    ),
    new Piatto(
      'anti17',
      'SEA OLIVES',
      'Desalted olives, minced fish, garlic, parsley, salt, pepper, breadcrumbs seasoned with turmeric',
      '<span>Courtesy of fisheasy</span>',
      'One leads to another….',
      '',
      'fish, cereals, egg, flour-based foods',
      'polpette_dipesce.png',
      'polpette_dipesce2.png',
      '9'
    ),
    new Piatto(
      'anti18',
      'FISH BALLS',
      'Chopped blue fish, boiled potatoes and parsley',
      '<span>Courtesy of fisheasy</span>',
      'A zero-calorie concentrate of flavour..',
      '',
      'fish, cereals, egg, flour-based foods',
      'polpette_dipesce.png',
      'polpette_dipesce2.png',
      '9'
    ),
    new Piatto(
      'anti19',
      'SWORDFISH TUNA AND MELON',
      'Melon with Swordfish and Tuna marinated with Pumpkin seeds',
      '€ 9.00',
      'The sweet freshness of melon, yellow or netted, together with the flavour of marinated tuna and swordfish, a natural sweet and sour',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamine, riboflavin, niacin and retinol.<br/><br/><span>Swordfish:</span> it is a concentrate of vitamins and mineral salts. And specifically, vitamin C, D and calcium. Allergens',
      'fish, pumpkin seeds, celery',
      'antipasti14_spadatonnomelone.png',
      'antipasti13_spadatonnomelone2.png',
      '13'
    ),
    new Piatto(
      'anti20',
      'PAPPA AL POMODORO WITH MARINATED SWORDFISH',
      'Marinated swordfish on tomato PAPPA, crunchy bread and confit cherry tomatoes',
      '€ 9.50',
      'A delicious encounter of land and sea: swordfish between pappa al pomodoro and crunchy bread... a true delight for your palate.',
      '<span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'Swordfish',
      'pappa_pomodoro.png',
      'pappa_pomodoro.png',
      '2'
    ),
    new Piatto(
      'anti21',
      'CRUNCHY RICE, POTATOES AND MUSSELS',
      'A classic from the Apulian cuisine reinterpreted by the Chef',
      '€ 8.50',
      'The sweet surprise of the Colfiorito potato contaminated by the fresh mussel is nothing compared to the rice proposed by the Chef...',
      '<span>Mussels:</span> rich in vitamins and minerals, with a large supply of antioxidants too.',
      'shellfish',
      'antipasti13_risopatatecozze.png',
      'antipasti13_risopatatecozze2.png',
      '2'
    ),


  ];

  primiEn = [
    new Piatto(
      'prim1',
      'MACCHERONCINI DI CAMPOFILONE CUTTLEFISH AND PEAS',
      'A sauce among the most classic of seafood cuisine with the uniqueness of Campofilone pasta, a combination of flavors.',
      '€ 12.50',
      'A sauce among the most classic of seafood cuisine with the uniqueness of Campofilone pasta, a combination of flavors.',
      '<span>Cuttlefish:</span> rich in vitamins A, D, B1 and B12, minerals such as selenium, phosphorus, potassium, iron, copper and zinc, fibre and purines.',
      'Mollusc, egg derivatives',
      'maccheroncini_seppiepiselli1.png',
      'maccheroncini_seppiepiselli2.jpg',
      '0'
    ),
    new Piatto(
      'prim2',
      'POTATOES GOODIES WITH CLAMS AND BOTTARGA',
      'Potato goodies, fresh clams with a sprinkling of bottarga (Mullet fish eggs).',
      '€ 10.50',
      'Potato goodies, fresh clams with a sprinkling of bottarga (Mullet fish eggs).',
      '<span>Clams:</span> a good amount of cobalamin (vitamin B12), essential for blood formation and the metabolism of proteins and fats; rich in mineral salts such as phosphorus, potassium and iodine, zinc, selenium.',
      'Mollusc, Fish',
      'gnocchi_vongole1.png',
      'gnocchi_vongole2.png',
      '0'
    ),
    // probabilmente da togliere ??
    new Piatto(
      'prim3',
      'FISHERMAN LASAGNA (RED SAUCE)',
      'Fresh egg puff pastry with a gurnard fish broth, shellfish, octopus, shrimps, cuttlefish seafood, in a crispy crust and bechamel',
      '€ 11.00',
      '',
      '<span>Gallinella (gurnard fish):</span>lean fish (about 90 kcal / 100 g), contain all the essential amino acids necessary to structure human proteins, while the fatty acids are mainly unsaturated ‐ polyunsaturated, with an excellent percentage of omega 3<br><span>Octopus:</span> it provides a valid supply of calcium, potassium, phosphorus, vitamins A and B1.<br><span>Shrimps:</span> rich in phosphorus, calcium, sodium, potassium, iron, selenium, zinc and iodine. When it comes to vitamins, these foods contain substantial levels of thiamin (B1), riboflavin (B2), niacin (PP) and retinol equivalents (pro A vitamin).',
      'Molluscs, crustaceans, fish, eggs and derivatives, milk‐based products',
      'lasagna_rossa1.png',
      'lasagna_rossa2.png',
      '0'
    ),
    new Piatto(
      'prim4',
      'WHITE LASAGNA WITH SEA BREAM AND SALMON',
      'Fresh egg puff pastry with lightly seared sea bream and sea bass, in béchamel sauce',
      '€ 11.00',
      '',
      '<span>Sea Bream:</span> it contains proteins, vitamins, mineral salts (calcium, phosphorus, iodine, iron) and it is rich in omega 3 fatty acids. <br><span>Salmon:</span> rich in Omega‐3 polyunsaturated fatty acids, vitamins A, D and B12.',
      'Molluscs, crustaceans, fish, eggs and derivatives, milk‐based products.',
      'lasagna_bianca1.png',
      'lasagna_bianca1.png',
      '0'
    )


  ];

  tranciEn = [
    new Piatto(
      'tranc1',
      'LARGE SEA BASS FILLET 280G',
      'Sea bass fillet, with a salads mixture of radicchio julienne, carrots and iceberg',
      '€ 25.00',
      'Simple and delicate, with a few calories.',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus.',
      'fish, celery',
      'granfiletto_spigola.png',
      'granfiletto_spigola3.jpg',
      '2'
    ),
    new Piatto(
      'tranc2',
      'LARGE SEA BREAM FILLET 280G',
      'Sea bream fillet with a salad of lettuce, pink grapefruit and celery',
      '€ 20.00',
      'A fat-free proposal, where sea bream is the protagonist',
      '<span>Sea bream:</span> it contains proteins, vitamins, mineral salts (calcium, phosphorus, iodine, iron) and it is rich in omega 3 fatty acids.',
      'fish, celery',
      'granfiletto_orata.png',
      'granfiletto_orata3.jpg',
      '13'
    ),
    new Piatto(
      'tranc3',
      'LARGE FILLET OF MULLET 280G',
      'Mullet fillet with seasonal salad, cabbage and mushrooms',
      '€ 16.50',
      'A fish particularly rich in omega 3 and au naturel to appreciate its strong and intense taste.',
      '<span>Mullet:</span> it contains a good amount of Omega 3, but also mineral salts such as phosphorus, magnesium, calcium and B6 vitamin',
      'fish, celery',
      'granfiletto_muggine.png',
      'granfiletto_muggine3.jpg',
      '2'
    ),
    new Piatto(
      'tranc4',
      'LARGE PERCH FILLET 280G',
      'Perch fillet with baby spinach salad, cherry tomatoes, long radicchio and iceberg',
      '€ 16.00',
      'Let&#39;s give space to the lake that like the sea, is delicate, nutritious and low in calories.',
      '<span>Perch:</span> rich in minerals and vitamins PP and D.',
      'fish',
      'granfiletto_persico.png',
      'granfiletto_persico3.jpg',
      '1'
    ),
    new Piatto(
      'tranc5',
      'SEA BREAM WITH VEGETABLES 180G',
      'Sea bream fillet with diced seasonal vegetables',
      '€ 12.50',
      'The sea bream with the scents of the garden in spring.',
      '<span>Sea bream:</span> it contains proteins, vitamins, mineral salts (calcium, phosphorus, iodine, iron) and it is rich in omega 3 fatty acids.',
      'fish, celery',
      'filetto_orataortaggi.png',
      'filetto_orataortaggi3.jpg',
      '2'
    ),
    new Piatto(
      'tranc6',
      'SALMON WITH COLFIORITO\'S RED POTATO CRUSH 180G',
      'Salmon fillet with Colfiorito\'s red potatoes, asparagus and Chef\'s mayonnaise with black squid ink',
      '€ 15.00',
      'All the goodness of Colfiorito potatoes with the noble proteins of salmon.',
      '<span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12.',
      'fish, celery, cereals, gluten, sesame',
      'filetto_salmonepatatarossa.png',
      'filetto_salmonepatatarossa2.png',
      '13'
    ),
    new Piatto(
      'tranc7',
      'SEA BASS IN CREAM OF PEAS AND CRUNCHY RICE 125G',
      'Sea bass fillet in peas cream cooked in vegetable broth',
      '€ 11.50',
      'Softness and crunchiness, with rice in an unusual shape.',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus.',
      'pesce, sedano, cereali, glutine',
      'filetti_turbantedispigola.png',
      'filetti_turbantedispigola3.jpg',
      '23'
    ),
    new Piatto(
      'tranc8',
      'SWORDFISH SLICE WITH SCAROLA SALAD AND PINE NUTS 180G',
      'Grilled swordfish steak, scarola salad sauteed with garlic, oil, thyme with toasted pine nuts',
      '€ 17.50',
      'In Sicily it has been a classic for centuries…. Taste it.',
      '<span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'fish, celery, nuts',
      'trancio_pescespadascarolapinoli.png',
      'trancio_pescespadascarolapinoli3.jpg',
      '2'
    ),
    new Piatto(
      'tranc9',
      'TUNA ON AVOCADO 180G',
      'Tuna steak with crispy sautéed vegetables on avocado',
      '€ 18.50',
      'A dish full of surprises, to be discovered one forkful after another.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.',
      'fish, celery',
      'trancio_tonnoavocado.png',
      'trancio_tonnoavocado3.jpg',
      '13'
    ),
    new Piatto(
      'tranc10',
      'SLICED TUNA WITH BRONTE PISTACHIOS 180G',
      'Seared and sliced tuna steak, crushed pistachios, caramelized onion and champignon mushrooms',
      '€ 18.00',
      'Veeeeeery good, with low fat content and all the noble proteins of Tuna.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.',
      'fish, celery, nuts',
      'trancio_tonnotagliatapistacchi.png',
      'trancio_tonnotagliatapistacchi3.jpg',
      '2'
    ),
    new Piatto(
      'tranc11',
      'GRILLED SEA BASS WITH POTATO ROST 180G',
      'Grilled sea bass with Colfiorito\'s red potato, rosti + courtesy salad',
      '€ 14.00',
      'Short, fat-free cooking with a crunchy potato surprise..',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus.',
      'fish, celery',
      'trancio_branzinoingraticola.png',
      'trancio_branzinoingraticola3.jpg',
      '3'
    ),
    new Piatto(
      'tranc12',
      'MULLET WITH TOMATOES, TAGGIASCA OLIVES AND CAPER FLOWER 180G',
      'Mullet steak with basil cherry tomatoes, olives and caper flower',
      '€ 12.00',
      'A concentrate of Mediterranean flavours, from north to south.',
      '<span>Mullet:</span> it contains a good amount of Omega 3, but also mineral salts such as phosphorus, magnesium, calcium and B6 vitamin',
      'fish, celery',
      'trancio_muggineconpomodorini.png',
      'trancio_muggineconpomodorini3.jpg',
      '13'
    ),
    new Piatto(
      'tranc13',
      'TRIO OF GRILLED FILLETS WITH VEGETABLE RATATUILLE 240G',
      'Sea bass, sea bream and perch fillet with vegetable and thyme ratatuille',
      '€ 16.00',
      'Black and white, a few seconds of cooking for maximum fragrance.',
      '<span>Sea bream:</span> it contains proteins, vitamins, mineral salts (calcium, phosphorus, iodine, iron) and it is rich in omega 3 fatty acids. <br/><br/><span>Sea bass:</span> contains many mineral salts, especially potassium and phosphorus <br/><br/><span>Perch:</span> rich in minerals and vitamins PP and D.',
      'fish, celery',
      'tris_filettigrigliati.png',
      'tris_filettigrigliati3.jpg',
      '2'
    ),
    new Piatto(
      'tranc14',
      'TRIO OF TUNA, SALMON AND SWORDFISH WITH CITRUS AND VEGETABLES 240G',
      'Tuna, swordfish and salmon fillets with spinach, citrus pie and wild berries',
      '€ 20.00',
      'Seared spinach and live grapefruit: a sweet and sour to taste.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.<br><br><span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12.<br/><br/><span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'fish, celery',
      'tris_agliagrumi.png',
      'tris_agliagrumi3.jpg',
      '13'
    ),
    new Piatto(
      'tranc15',
      'PERCH FILLET WITH UMBRIA\'S LENTILS 120G',
      'Perch fillet, Colfiorito\'s lentils and Cannara\'s onion, a non-typical Umbrian dish....',
      '€ 11.50',
      'From Castelluccio al Trasimeno to Cannara, the Umbria region concentrated in one dish.',
      '<span>Perch:</span> rich in minerals and vitamins PP and D.',
      'fish, celery, cereals, flour-based foods',
      'filetto_persicoconlenticchie.png',
      'filetto_persicoconlenticchie3.jpg',
      '23'
    ),


  ];

  burgerEn = [
    new Piatto(
      'burg1',
      'WHITE FISHBURGER 130G',
      'Sesame seed bun, with Perchfish burger, purple cap, green tomato, salad and almonds',
      '€ 10.50',
      'Combination of lake colours and flavours in one bite.',
      '<span>Perch:</span> rich in minerals and vitamins PP and D. <br/><br/><span>Trout:</span> rich in minerals, iron and potassium and with low sodium intake.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'fishburger_bianco.png',
      'fishburger_bianco3.jpg',
      '2'
    ),

    new Piatto(
      'burg2',
      'BLUE FISHBURGER 130G',
      'Sesame seed bun with Mullet burger, Belgian endive, mayonnaise, lettuce salad, tomato, crushed walnuts and guacamole',
      '€ 10.50',
      'The strong flavour of mullet, its noble proteins, enriched with fresh vegetables.',
      '<span>Mullet:</span> it contains a good amount of Omega 3, but also mineral salts such as phosphorus, magnesium, calcium and B6 vitamin',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'fishburger_azzurro.png',
      'fishburger_azzurro3.jpg',
      '13'
    ),
    new Piatto(
      'burg3',
      'FISHBURGER DELUXE - TUNA 130G',
      'Ancien seed bun with Sea bass, swordfish and tuna burger, champignon mushrooms, radicchio with grain mustard, mayonnaise, chopped pistachios',
      '€ 13.50',
      'A mix of noble fish in one bite, paired with a bitter sweet sensation.',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus. <br/><br/><span>Swordfish:</span> it is a concentrate of vitamins and mineral salts. Specifically, vitamin C, D and calcium. <br/><br/><span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'fishburger_deluxetonno.png',
      'fishburger_deluxetonno3.jpg',
      '23'
    ),
    new Piatto(
      'burg4',
      'FISHBURGER DELUXE - SALMON 130G',
      'Ancien seed bun with Sea bass, swordfish and salmon burger, guacamole, carrots, lettuce salad, long baked radicchio, mayonnaise',
      '€ 12.00',
      'A combination that leaves a sweet sensation on the palate.',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus. <br/><br/><span>Swordfish:</span> it is a concentrate of vitamins and mineral salts. Specifically, vitamin C, D and calcium. <br/><br/><span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'fishburger_deluxesalmone.png',
      'fishburger_deluxesalmone3.jpg',
      '3'
    ),
    new Piatto(
      'burg5',
      'TUNA AND HAKEFISHBURGER 130G',
      'Ancien seed bun with Tuna and hake burger, oblong tomato, salad, grilled aubergines, sweet and sour onion, valerian',
      '€ 13.50',
      'Delicate and decisive, with the sweet touch of Cannara onion.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamine, riboflavin, niacin and retinol. <br/><br/><span>Hake:</span> rich in proteins, omega 3 and mineral salts such as potassium, iron, calcium and phosphorus.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'fishburger_tonnoenasello.png',
      'fishburger_tonnoenasello3.jpg',
      '1'
    ),
    new Piatto(
      'burg6',
      'PERCH AND SALMON FISHBURGER 130G',
      'Sesame seed bun with Perchfish and salmon burger with guacamole, green tomato, grilled eggplant',
      '€ 10.50',
      'Tasty and succulent with an exotic soul.',
      '<span>Perch:</span> rich in minerals and vitamins PP and D.<br/><br/><span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'fishburger_persiosalmone.png',
      'fishburger_persicosalmone3.jpg',
      '12'
    ),
    new Piatto(
      'burg7',
      'HAKE STICK (1 HG)',
      '1 breaded hake fillet',
      '€ 11.00',
      'Just hake beaten with a knife, without other “intermediaries”. Crunchy potatoes and vegetables complete it.',
      '<span>Hake:</span> rich in proteins, omega 3 and mineral salts such as potassium, iron, calcium and phosphorus.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'stick_nasello.png',
      'stick_nasello3.jpg',
      '3'
    ),
    new Piatto(
      'burg8',
      'HAKE STICK (2 HG)',
      '2 breaded hake fillet',
      '€ 17.00',
      'Just hake beaten with a knife, without other “intermediaries”. Crunchy potatoes and vegetables complete it.',
      '<span>Hake:</span> rich in proteins, omega 3 and mineral salts such as potassium, iron, calcium and phosphorus.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'stick_nasello2.png',
      'stick_nasello2_3.jpg',
      '3'
    ),
    new Piatto(
      'burg9',
      'FISH AND CHIPS',
      'Breaded sea bass fillet with crisps and valerian salad with crunchy seeds',
      '€ 12.50',
      'A non-fried breading, with the utmost respect for the fish inside.',
      '<span>Sea bass:</span> it contains many mineral salts, especially potassium and phosphorus.',
      'fish, celery, mustard, sesame, nuts, cereals, gluten, eggs, milk',
      'stick_fishandchips.png',
      'stick_fishandchips3.jpg',
      '23'
    ),

    new Piatto(
      'burg10',
      'GRAN FRITTO MISTO (fried fish)',
      'Delicata Tempura di pesce, crostacei, frutti di mare, cavolfiore, carote, cipolle e zucchine',
      '€ 13.50',
      'Pesce spinato, frutti di mare e crostacei, tutti insieme come nella rete del pescatore,  in delicata tempura con qualche veg che nei nostri piatti non manca mai. Un’estasi “papillogustativa”...',
      '<span>Spigola:</span> contiene molti sali minerali, in particolar modo di potassio e fosforo <br><br><span>Orata:</span> contiene proteine, vitamine, sali minerali (calcio, fosforo, iodio, ferro) ed è ricca di acidi grassi omega 3.<br><br><span>Pesce Persico:</span> ricco di sali minerali e vitamine PP e D.<br><br><span>Mazzancolle:</span> ricche di fosforo, calcio, sodio, potassio, ferro, selenio, zinco e iodio. Per quel che concerne le vitamine, questi alimenti contengono livelli notevoli di tiamina (B1), riboflavina (B2), niacina (PP) e retinolo equivalenti (pro vitamina A).<br><br><span>Cannelli:</span> ricchissimi di amminoacidi essenziali, sali minerali (sodio, iodio ecc) e vitamine idrosolubili.',
      'fish, shellfish, sulphites',
      'fritto_misto.png',
      'fritto_misto2.jpg',
      '0'
    ),
  ];

  saladEn = [
    new Piatto(
      'salad1',
      'SALMONFISH SALAD (1HG)',
      '1hg grilled Salmon cubes, Purple cap, ice fennel, cherry tomato, lettuce, valerian',
      '€ 9.00',
      'Three simple and delicious salads, for a light, nutritious snack with zero saturated fat.',
      '<span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12. ',
      'fish, celery, nuts',
      'salmon_fishsalad.png',
      'salmon_fishsalad3.jpg',
      '1'
    ),
    new Piatto(
      'salad2',
      'SALMONFISH SALAD (2HG)',
      '2hg grilled Salmon cubes, Purple cap, ice fennel, cherry tomato, lettuce, valerian',
      '€ 14.50',
      'Three simple and delicious salads, for a light, nutritious snack with zero saturated fat.',
      '<span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12. ',
      'fish, celery, nuts',
      'salmon_fishsalad.png',
      'salmon_fishsalad3.jpg',
      '1'
    ),
    new Piatto(
      'salad3',
      'SWORDFISH SALAD (1HG)',
      '1hg grilled swordfish cubes with boiled potatoes, radicchio and walnuts, red turnips',
      '€ 12.00',
      'Three simple and delicious salads, for a light, nutritious snack with zero saturated fat.',
      '<span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'fish, celery, nuts',
      'swordfish_fishsalad.png',
      'swordfish_fishsalad3.jpg',
      '2'
    ),
    new Piatto(
      'salad4',
      'SWORDFISH SALAD (2HG)',
      '2hg grilled swordfish cubes with boiled potatoes, radicchio, walnuts, and red turnips',
      '€ 16.50',
      'Three simple and delicious salads, for a light, nutritious snack with zero saturated fat.',
      '<span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'fish, celery, nuts',
      'swordfish_fishsalad.png',
      'swordfish_fishsalad3.jpg',
      '1'
    ),
    new Piatto(
      'salad5',
      'TUNAFISH SALAD (1HG)',
      '1hg seared tuna cubes with sesame, rocket, datterini tomatoes, iceberg, carrots, valerian',
      '€ 12.50',
      'Three simple and delicious salads, for a light, nutritious snack with zero saturated fat.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.',
      'fish, celery, nuts',
      'tuna_fishsalad.png',
      'tuna_fishsalad3.jpg',
      '1'
    ),
    new Piatto(
      'salad6',
      'TUNAFISH SALAD (2HG)',
      '2hg seared tuna cubes with sesame, rocket, datterini tomatoes, iceberg, carrots, valerian',
      '€ 18.50',
      'Three simple and delicious salads, for a light, nutritious snack with zero saturated fat.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.',
      'fish, celery, nuts',
      'tuna_fishsalad.png',
      'tuna_fishsalad3.jpg',
      '1'
    ),
    new Piatto(
      'salad7',
      'FANTASY SALAD – TUNA',
      'Seared tuna cubes with lettuce, cucumber, sliced almonds, potatoes, avocado, rocket and raspberries',
      '€ 18.50',
      'Tuna with the flavours of the land and a touch of exotic fruit.',
      '<span>Tuna:</span> it contains a significant amount of high biological value proteins and essential fats and omega 3; it is also rich in vitamins: thiamin, riboflavin, niacin and retinol.',
      'fish, celery, nuts',
      'insalatafantasia_tonno.png',
      'insalatafantasia_tonno3.jpg',
      '1'
    ),
    new Piatto(
      'salad8',
      'FANTASY SALAD – SALMON',
      'Grilled Salmon cubes with raw baby spinach, tzaziky , Taggiasca olives, pineapple, purple cap and wild berries',
      '€ 13.00',
      'The exotic, the scents of Liguria and the Ionian ones flavour the King of Nordic fish.',
      '<span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12.',
      'fish, sesame ',
      'insalatafantasia_salmone.png',
      'insalatafantasia_salmone3.jpg',
      '3'
    ),
    new Piatto(
      'salad9',
      'FANTASY SALAD – MULLET',
      'Grilled Mullet in white / purple cap salad, champignon mushrooms, pomegranate, valerian, radishes',
      '€ 10.50',
      'It stimulates the palate, with the pungent flavour of pomegranate, embellished with bottarga.',
      '<span>Mullet:</span> it contains a good amount of Omega 3, but also mineral salts such as phosphorus, magnesium, calcium and B6 vitamin',
      'fish, celery, nuts',
      'insalatafantasia_muggine.png',
      'insalatafantasia_muggine3.jpg',
      '13'
    ),
    new Piatto(
      'salad10',
      'FANTASY SALAD – SWORDFISH',
      'Grilled Swordfish cubes with Julienne carrots, walnuts, grapefruit, sour onion, rocket, ball green apple and purple cabbage tuft',
      '€ 14.50',
      'The apple contains all the flavour of the fruit, for an overall sensation of sure pleasure.',
      '<span>Swordfish:</span> it is a concentrate of vitamins and minerals. And specifically, vitamin C, D and calcium.',
      'fish, celery, nuts',
      'insalatafantasia_spada.png',
      'insalatafantasia_spada2.png',
      '3'
    ),
    new Piatto(
      'salad11',
      'SALMON SALAD MARINATED',
      'Marinated salmon, baby spinach, chopped avocado, lime, cherry tomatoes, pineapple, ice celery',
      '€ 11.50',
      'A unique enveloping and exotic flavour.',
      '<span>Salmon:</span> rich in Omega-3 polyunsaturated fatty acids, vitamins A, D and B12.',
      'fish, celery',
      'insalatafantasia_salmonemarinato.png',
      'insalatafantasia_salmonemarinato3.jpg',
      '3'
    ),

  ];

  dolciEn = [
    /* new Piatto(
       'dolci1',
       'IL CAFFÈ ALLA MOKA LO OFFRIAMO NOI',
       '',
       '',
       '',
       '',
       'grano, mandorle, uova, latte',
       'salmon_fishsalad.png',
       'salmon_fishsalad3.jpg',
       '1'
     ),*/
    new Piatto(
      'dolci2',
      'FISHEASY TART',
      'Lemon shortcrust pastry, custard, wild berries',
      '€ 8.00',
      'The bush basket.',
      '',
      'Cereals, gluten, eggs, milk, fish',
      'crostatina.png',
      'crostatina3.jpg',
      '1'
    ),
    new Piatto(
      'dolci3',
      'RASPBERRY CHEESECAKE',
      'Spreadable cheese, biscuit, jellies and raspberry sauce all made "in-house"',
      '€ 6.00',
      'Once upon a time in America',
      '',
      'Cereals, gluten, eggs, milk, fish',
      'cheescake.png',
      'cheescake3.jpg',
      '1'
    ),
    new Piatto(
      'dolci4',
      'PASSION CAKE',
      'Passion fruit, ricotta, raspberry and passion fruit jellies',
      '€ 6.00',
      'An exotic journey',
      '',
      'Cereals, gluten, eggs, milk, fish',
      'semifreddo.png',
      'semifreddo3.jpg',
      '1'
    ),
    new Piatto(
      'dolci5',
      'TIRAMISÙ',
      'Savoiardi, coffee, mascarpone cheese, with lemon flavour',
      '€ 6.50',
      'Classic but not that much',
      '',
      'Cereals, gluten, eggs, milk, fish',
      'tiramisu2.png',
      'tiramisu3.jpg',
      '5'
    ),
  ];


  birre = [

    new Drink(
      '1',
      'CORONA EXTRA',
      'CL.33',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'PILSNER URQUELL',
      'CL.33',
      '€ 4.50',
      '1',
    ),

    new Drink(
      '1',
      'PERONI GRAN RISERVA BIANCA',
      'CL.33',
      '€ 5.50',
      '1',
    ),

  ];

  arti33 = [

    new Drink(
      '1',
      'BALADIN NAZIONALE',
      'CL.33',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'LISA BIRRA DEL BORGO',
      'CL.33',
      '€ 4.50',
      '1',
    )
  ];

  arti75 = [
    new Drink(
      '1',
      'OPPERBACCO 4.7',
      'CL.75',
      '€ 14.00',
      '1',
    ),
    new Drink(
      '1',
      'OPPERBACCO BIANCA PIPERITA',
      'CL.75',
      '€ 14.00',
      '1',
    ),
  ];

  vini = [

    new Drink(
      '1',
      'PASSERINA - SANTORI',
      'CL.37,5',
      '€ 11.50',
      '1',
    ),

    new Drink(
      '1',
      'VERDICCHIO DI MATELICA - BISCI',
      'CL.37,5',
      '€ 11.50',
      '1',
    ),
  ];

  viniRossi = [
    new Drink(
      '1',
      'LAGREIN ROSSO - ERSTE & NEUE',
      'CL.75',
      '€ 18.50',
      '1',
    )
  ];

  champ37 = [

    new Drink(
      '1',
      'FERGHETTINA BRUT FRANCIACORTA DOCG',
      'CL. 37,5',
      '€ 20.00',
      '1',
    ),

    new Drink(
      '1',
      'FERRARI ROSÉ TRENTO DOC',
      'CL. 37,5',
      '€ 28.00',
      '1',
    ),

    new Drink(
      '1',
      'LAURENT-PERRIER LA CUVÉE CHAMPAGNE',
      'CL. 37,5',
      '€ 35.00',
      '1',
    ),

  ];

  vini75 = [
    new Drink(
      '1',
      'SELIDA CRU GEWURZTRAMINER - TRAMIN',
      'CL.75',
      '€ 22.00',
      '1',
    ),
    new Drink(
      '1',
      'GEWURZTRAMINER DOC - ANDRIAN',
      'CL.75',
      '€ 16.00',
      '1',
    ),
    new Drink(
      '1',
      'ERSTE & NEUE CHARDONNAY ALTO ADIGE DOC',
      'CL.75',
      '€ 16.00',
      '1',
    ),
    new Drink(
      '1',
      'MŰLLER THURGAU ALTO ADIGE - TRAMIN',
      'CL.75',
      '€ 16.00',
      '1',
    ),
    new Drink(
      '1',
      'PINOT BIANCO - TRAMIN',
      'CL.75',
      '€ 18.00',
      '1',
    ),
    new Drink(
      '1',
      'SAUVIGNON ALTO ADIGE - TRAMIN',
      'CL75',
      '€ 20,00',
      '1'
    ),
    new Drink(
      '1',
      'FALANGHINA - MASTROBERARDINO',
      'CL.75',
      '€ 18,00',
      '1'
    ),

    new Drink(
      '1',
      'FIANO DI AVELLINO - MASTROBERARDINO',
      'CL75',
      '€ 20,00',
      '1'
    ),
    new Drink(
      '1',
      'GRECO DI TUFO - MASTROBERARDINO',
      'CL75',
      '€ 20,00',
      '1'
    ),
    new Drink(
      '1',
      'VERDICCHIO DI MATELICA - BISCI',
      'CL.75',
      '€ 16,00',
      '1'
    ),
    new Drink(
      '1',
      'VERD. DI MATELICA - LA MONACESCA',
      'CL.75',
      '€ 16,00',
      '1'
    ),
    new Drink(
      '1',
      'VERD. DI MATELICA RISERVA CAMBRUGIANO - BELISARIO',
      'CL.75',
      '€ 24,00',
      '1'
    ),
    new Drink(
      '1',
      'VERDICCHIO DI JESI CLASSICO SUPERIORE',
      'MACRINA - GAROFOLI CL.75',
      '€ 15,00',
      '1'
    ),
    new Drink(
      '1',
      'VERDICCHIO CASTELLI DI JESI S.MICHELE-',
      'BONCI CL.75',
      '€ 28,00',
      '1'
    ),
    new Drink(
      '1',
      'PECORINO BIO-CENTANNI ',
      'CL75',
      '€ 16,00',
      '1'
    ),
    new Drink(
      '1',
      'PASSERINA IGT BIO-COLLEVITE ZQ',
      'CL.75',
      '€ 13,00',
      '1'
    ),
    new Drink(
      '1',
      'BIANCHELLO METAURO SAN CESAREO-',
      'MORELLI CL75',
      '€ 14,00',
      '1'
    ),
    new Drink(
      '1',
      'STRONGOLO FIOR D ACASIA INCROCIO BRUNI54 IGT',
      'CL75',
      '€ 14,50',
      '1'
    ),
    new Drink(
      '1',
      'FALERIO VIGNA SOLARIA - VELENOSI',
      'CL75',
      '€ 16,00',
      '1'
    ),
    new Drink(
      '1',
      'VERMENTINO SARDEGNA LE GIARE-CANTINA DI MOGORO',
      'CL75',
      '€ 14,00',
      '1'
    ),
    new Drink(
      '1',
      'VERMENTINO SARDEGNA DON GIOVANNI CANTINA DI MOGORO',
      'CL75',
      '€ 17,00',
      '1'
    ),
    new Drink(
      '1',
      'TREBBIANO SPOLETINO SELEZIONE“DEL POSTO” - PERTICAIA',
      'CL75',
      '€ 26,00',
      '1'
    ),
    new Drink(
      '1',
      'PIGATO Riviera Ligure Ponente DOC-LUNAE BOSONI CL.75',
      'CL75',
      '€ 28,00',
      '1'
    )

  ];

  calici = [

    new Drink(
      '1',
      'CALICE PASSERINA – BIO COLLEVITE ',
      '',
      '€ 4.00',
      '1',
    ),

    new Drink(
      '1',
      'CALICE FIOR D’ACASIA - STROLOGO',
      '',
      '€ 5.50',
      '1',
    ),

    new Drink(
      '1',
      'CALICE SPUMANTE BRUT - CASA COLLER',
      '',
      '€ 4.50',
      '1',
    ),

    new Drink(
      '1',
      'CALICE VERMENTINO “LE GIARE”',
      '',
      '€ 5.50',
      '1',
    ),
    new Drink(
      '1',
      'CALICE DI ROSSO PICENO - BIO COLLEVITE',
      '',
      '€ 5.50',
      '1',
    ),


  ];

  bollicine = [

    new Drink(
      '1',
      'CA’ DEL BOSCO PRESTIGE',
      'Franciacorta CL.75',
      '€ 60.00',
      '1',
    ),
    new Drink(
      '1',
      'BORGO PAGLIANETTO VERDICCHIO MATELICA BRUT',
      'Verdicchio spumantizzato CL.75',
      '€ 30.00',
      '1',
    ),
    new Drink(
      '1',
      'PROSECCO EXTRA DRY BORGOLUCE',
      'Prosecco di Valdobbiadene CL.75',
      '€ 18.00',
      '1',
    ),
    new Drink(
      '1',
      'BLINK SPUMANTE BRUT ROSÈ',
      'Uve Vernaccia Nera CL.75',
      '€ 18.00',
      '1',
    ),
    new Drink(
      '1',
      'MOSCATO ASTI (DOLCE)',
      'CL.75',
      '€ 14.00',
      '1',
    ),

    new Drink(
      '1',
      'PROSECCO - CASA COLLER',
      'Alta Brianza CL.75',
      '€ 13.50',
      '1',
    ),
  ];

  champ = [

    new Drink(
      '1',
      'DOM',
      'Ml. 750',
      'Prezzo su richiesta',
      '1',
    ),

    new Drink(
      '1',
      'MOET & CHANDON',
      'CL. 75',
      '€ 85.00',
      '1',
    ),

    new Drink(
      '1',
      'VEUVE CLICQUOT',
      'CL.75',
      '€ 85.00',
      '1',
    ),

  ];

  acqua = [

    new Drink(
      '1',
      'LATTINA ACQUA RECORARO ',
      'Naturale o Frizzante CL.33',
      '€ 1.50',
      '1',
    ),

    new Drink(
      '1',
      'L.RECOARO VANITY',
      'Naturale o Frizzante CL.75',
      '€ 3.00',
      '1',
    ),

    new Drink(
      '1',
      'PERRIER',
      'Molto frizzante CL.75',
      '€ 4.50',
      '1',
    ),

    new Drink(
      '1',
      'NEPI ACQUA EFFERVESCENTE NATURALE',
      'CL.75',
      '€ 3.50',
      '1',
    ),
    new Drink(
      '1',
      'PANNA',
      'Naturale CL.75',
      '€ 3.00',
      '1',
    ),
    new Drink(
      '1',
      'S.PELLEGRINO ACQUA',
      'Frizzante CL.75',
      '€ 3.00',
      '1',
    ),
    new Drink(
      '1',
      'SAN BENEDETTO PRESTIGE',
      'Naturale o Frizzante CL.75',
      '€ 3.00',
      '1',
    ),
    new Drink(
      '1',
      'ACQUA TULLIA',
      'Naturale, Lievemente frizzante, Frizzante CL.75',
      '€ 2.00',
      '1',
    ),
    new Drink(
      '1',
      'VALVERDE ACQUA',
      'Naturale o Lievemente Frizzante CL.75',
      '€ 2.50',
      '1',
    ),

    new Drink(
      '1',
      'NORDA',
      'Naturale o Frizzante',
      '€ 3.00',
      '1',
    ),
    new Drink(
      '1',
      'FONTE DELLA SALUTE',
      'Naturale CL 0.66',
      '€ 3.00',
      '1',
    ),

  ];

  rum = [

    new Drink(
      '1',
      'APPLETON ESTATE ',
      'Jamaica 12',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'PAPAGAYO BLANCO',
      'Paraguay',
      '€ 4.00',
      '1',
    ),

    new Drink(
      '1',
      'SANTA TERESA 1796',
      'Venezuela',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'DON PAPA - BARONKO',
      'Filippine',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'DIPLOMATICO',
      'Venezuela',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'ZACAPA centenario',
      'Guatemala 23',
      '€ 7.00',
      '1',
    ),


  ];

  vodka = [

    new Drink(
      '1',
      'WHITE BIRCH ',
      '',
      '€ 4.00',
      '1',
    ),

    new Drink(
      '1',
      'FIVE LAKE',
      '',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'ZALOWKA',
      '',
      'Vodka al limone € 3.00',
      '1',
    ),


  ];

  gin = [

    new Drink(
      '1',
      'LONDON HILL ',
      '',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'MIL',
      'GIN botanico ',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'CRAFTER AROMATIC FLOWERS',
      'GIN aromatico',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'CRAFTER BLU LONDON DRY GIN',
      'GIN Secco',
      '€ 6.00',
      '1',
    ),


  ];

  ver = [

    new Drink(
      '1',
      'FRANCOLLI GRAPPA RISERVA BAROLO',
      'Grappa Barricata',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'GRAPPA AL VERDICCHIO DI MATELICA - BORGO PAGLIANETTO',
      'Grappa Bianca',
      '€ 4.50',
      '1',
    ),

    new Drink(
      '1',
      'MERLET',
      'Cognac',
      '€ 5.00',
      '1',
    ),


  ];

  liquori = [

    new Drink(
      '1',
      'VARNELLI ANICE ',
      'Anice secco',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'AMARO DEL CAPO',
      'Amaro',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'FERNET BRANCA',
      'Digestivo',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'BRANCAMENTA',
      'Amaro',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'AMARO MONTENEGRO',
      'Amaro',
      '€ 3.50',
      '1',
    ),

    // da cancellare forse
    new Drink(
      '1',
      'JAGERMEISTER ',
      'Amaro',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'MOLINARI SAMBUCA',
      'Liquore dolce e profumato',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'LIMONCELLO',
      'Liquore',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'VIPARO - AMARO',
      'Liquore',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'Q ROSSO',
      'Vermouth rosso',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'MARTINI DRY',
      'Vermouth bianco',
      '€ 3.50',
      '1',
    ),


  ];

  whisky = [

    new Drink(
      '1',
      'HIGHLAND HARVEST SAUTERNES ',
      '',
      '€ 6.50',
      '1',
    ),

    new Drink(
      '1',
      'JURA ELISIR 12',
      '',
      '€ 8.00',
      '1',
    ),

    new Drink(
      '1',
      'JACK DANIELS BOURBON',
      '',
      '€ 4.00',
      '1',
    ),

    new Drink(
      '1',
      'Talisker Skye',
      '',
      '€ 5.00',
      '1',
    ),

  ];

  tequila = [

    new Drink(
      '1',
      'DON ABRAHAM ORGANIC BLANCO ',
      'Tequila Premium',
      '€ 7.00',
      '1',
    ),

    new Drink(
      '1',
      'JOSÈ CUERVO',
      'Tequila',
      '€ 3.50',
      '1',
    ),


  ];

  bitter = [

    new Drink(
      '1',
      'CAMPARI BITTER ',
      '',
      '€ 3.00',
      '1',
    ),

    new Drink(
      '1',
      'CAMPARI SODA',
      '',
      '€ 3.00',
      '1',
    ),


  ];

  cock = [

    new Drink(
      '1',
      'NEGRONI ',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'AMERICANO',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'BOULEVARDIER ',
      '',
      '€ 7.00',
      '1',
    ),

    new Drink(
      '1',
      'APEROL SPRITZ',
      '',
      '€ 5.50',
      '1',
    ),

    new Drink(
      '1',
      'CAMPARI SPRITZ',
      '',
      '€ 5.50',
      '1',
    ),

    new Drink(
      '1',
      'LONDON HILL TONIC ',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'VODKA MARTINI',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'GINGER BEER COCKTAIL ',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'MOSCOW MULE',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'MARGARITA ',
      '',
      '€ 7.00',
      '1',
    ),

    new Drink(
      '1',
      'VESPER',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'BLACK RUSSIAN ',
      '',
      '€ 7.00',
      '1',
    ),

    new Drink(
      '1',
      'COSMOPOLITAN',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'LONG ISLAND ',
      '',
      '€ 7.00',
      '1',
    ),

    new Drink(
      '1',
      'MOJITO',
      '',
      '€ 6.00',
      '1',
    ),

    new Drink(
      '1',
      'DARK AND STORMING',
      '',
      '€ 6.00',
      '1',
    ),


  ];

  precock = [

    new Drink(
      '1',
      'MIL GIN E TONIC ',
      '',
      '€ 9.00',
      '1',
    ),

    new Drink(
      '1',
      'CAIPIRINHA',
      '',
      '€ 9.00',
      '1',
    ),

    new Drink(
      '1',
      'DAIQUIRI ',
      '',
      '€ 9.00',
      '1',
    ),

    new Drink(
      '1',
      'MANHATTAN',
      '',
      '€ 9.00',
      '1',
    ),

    new Drink(
      '1',
      'OLD FASHIONED ',
      '',
      '€ 9.00',
      '1',
    ),


  ];

  soft = [

    new Drink(
      '1',
      'FEVER TREE TONIC ',
      'CL. 20',
      '€ 3.50',
      '1',
    ),

    new Drink(
      '1',
      'SCHWEPPES LEMON',
      'CL. 20',
      '€ 3.00',
      '1',
    ),

    new Drink(
      '1',
      'COCA COLA ',
      'Lattina CL. 25',
      '€ 3.00',
      '1',
    ),
  ];

  succhi = [

    new Drink(
      '1',
      ' SUCCHI DI FRUTTA',
      'Frutta Vari CL. 20',
      '€ 3.00',
      '1',
    ),


  ];

  display0: string;
  display1: string;
  display2: string;
  display3: string;

  ngOnInit(): void {

    let oggi = new Date();
    let mese = oggi.getMonth();


    /*
        //gennaio
        if (mese == 11) {
          this.display0 = "0";
          this.display1 = "2";
          this.display2 = "12";
          this.display3 = "23";
        }

        //febbraio
        if (mese == 2) {
          this.display0 = "0";
          this.display1 = "3";
          this.display2 = "13";
          this.display3 = "23";
        }

        //marzo
        if (mese == 0) {
          this.display0 = "0";
          this.display1 = "1";
          this.display2 = "12";
          this.display3 = "13";
        }

        //aprile
        if (mese == 1) {
          this.display0 = "0";
          this.display1 = "2";
          this.display2 = "12";
          this.display3 = "23";
        }

        //maggio
        if (mese == 3) {
          this.display0 = "0";
          this.display1 = "3";
          this.display2 = "13";
          this.display3 = "23";
        }

        //giugno
        if (mese == 4) {
          this.display0 = "0";
          this.display1 = "1";
          this.display2 = "12";
          this.display3 = "13";
        }

        //luglio
        if (mese == 5) {
          this.display0 = "0";
          this.display1 = "2";
          this.display2 = "12";
          this.display3 = "23";
        }

        //agosto
        if (mese == 6) {
          this.display0 = "0";
          this.display1 = "3";
          this.display2 = "13";
          this.display3 = "23";
        }
        //settembre
        if (mese == 7) {
          this.display0 = "0";
          this.display1 = "1";
          this.display2 = "12";
          this.display3 = "13";
        }

        //ottobre
        if (mese == 8) {
          this.display0 = "0";
          this.display1 = "2";
          this.display2 = "12";
          this.display3 = "23";
        }

        //novembre
        else if (mese == 9) {
          this.display0 = "0";
          this.display1 = "3";
          this.display2 = "23";
          this.display3 = "13";

        }
        //dicembre
        else if (mese == 10) {
          this.display0 = "0";
          this.display1 = "1";
          this.display2 = "12";
          this.display3 = "13";
        }
    */

    this.display0 = '0';
    this.display1 = '3';
    this.display2 = '13';
    this.display3 = '23';


    let titolone = document.getElementById('titolone') as HTMLElement;

    // var lingua = "ricette";

    if (titolone.innerHTML == 'OUR') {
      this.inglese = true;
      // lingua = "ericette";

    }

    window.scrollTo(0, 0);

    AOS.init({
      duration: 3000
    });

    let menu = document.getElementById('check') as HTMLInputElement;
    if (menu.checked == true) {
      menu.checked = false;
    }
  }

}
