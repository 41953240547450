import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AnimationsComponent } from './animations/animations.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FisheasyComponent } from './fisheasy/fisheasy.component';
import { MaterieprimeComponent } from './materieprime/materieprime.component';
import { EasybarComponent } from './easybar/easybar.component';
import { EasyathomeComponent } from './easyathome/easyathome.component';
import { LavoraComponent } from './lavora/lavora.component';
import { ContattiComponent } from './contatti/contatti.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PiattoComponent } from './piatto/piatto.component';
import { EasyLunchComponent } from './easy-lunch/easy-lunch.component';
import { ProposteComponent } from './proposte/proposte.component';
import { AllergeniComponent } from './allergeni/allergeni.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FisheasyComponent,
    MaterieprimeComponent,
    EasybarComponent,
    EasyathomeComponent,
    LavoraComponent,
    ContattiComponent,
    FooterComponent,
    AnimationsComponent,
    HomepageComponent,
    PiattoComponent,
    EasyLunchComponent,
    ProposteComponent,
    AllergeniComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
