<div class="row m-0 p-0 justify-content-center sfondostriche">
    <div class="col-md-8 row mx-0 p-0 justify-content-center  mt-200">
        <div class="col-md-6 gruppetto">
            <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde">
            <img src="assets/img/imm2_fisheasy.jpg" class="imm-sov imm-padella">
            <img src="assets/img/imm3_fisheasy.jpg" class="imm-sov imm-lampadari">


        </div>
        <div class="col-md-6">
            <img src="assets/img/logo_orizzontale.png" class="logolungo">
            <div class="lineablu"></div>

            <p i18n>
                Fisheasy Bistrò nasce da un'idea semplice:
                portare il pesce di qualità a tavola nella sua
                forma più genuina e salutare,
                completamente lavorato dal nostro chef,
                spinato e pronto da mangiare.</p>

            <p i18n>
                La nostra è una cucina orientata a
                promuovere un'alimentazione sana e
                questo rende le nostre pietanze adatte agli
                amanti del pesce, agli sportivi ed anche ai
                bambini.
            </p>
        </div>
        <div class="col-md-6">
            <p i18n> Scegliamo solo materie prime di alta
                qualità e prepariamo piatti gustosi e
                sfiziosi, che mantengono intatte le
                proprietà nutritive e organolettiche del
                pesce, alimento principe del benessere.</p>
            <p i18n>
                Con Fisheasy Bistrò vogliamo
                portare un po' di mare in Umbria, per "navigare" insieme a voi restando fermi a
                tavola.</p>
        </div>

        <div class="col-md-4">
            <img src="assets/img/imm1_fisheasy.jpg" >
        </div>

        <div class="col-12 divisore"></div>
        <div class="col-md-6 gruppetto">
            <img src="assets/img/onde_graphic.png" class="imm-sov imm-onde">
            <img src="assets/img/imm4_fisheasy.jpg" class="imm-sov imm-rete">
            <img src="assets/img/imm5_fisheasy.jpg" class="imm-sov imm-tavoli">
            <img src="assets/img/imm6_fisheasy.jpg" class="imm-sov imm-serranda">


        </div>
        <div class="col-md-6">
            <h2 class="pag-tit text-center" i18n>IL BISTRÒ</h2>
            <div class="lineablu"></div>

            <p i18n>
                Un ambiente accogliente e dal design
contemporaneo che si ispira al contesto
del mare. Luci soffuse e arredo minimal
curato nei dettagli, per creare
un'esperienza che abbraccia vista e gusto.</p>

            <p i18n>
                Musica soffusa in sottofondo, una nutrita carta di
vini e birre artigianali, oltre ad acque selezionate, per
accompagnare il vostro viaggio alla
scoperta del nostro mare. In Fisheasy vi
accogliamo nel calore di un luogo che
attiva tutti i vostri sensi.
            </p>
        </div>
    </div>




</div>
<div class="col-12 divisore"></div>
