<div class="row mx-0 px-0 justify-content-center sfondosquamato pb-100">
    <div class="col-md-6 mt-200 row justify-content-center">
        <h2 class="col-md-12 pag-tit text-center"  i18n>SOSTANZE O PRODOTTI
            CHE PROVOCANO 
            ALLERGIE O INTOLLERANZE
            </h2>
        <div class="lineablu "></div>



        <div class="col-md-12"></div>
        <h5 class="col-md-12 tit-azz text-center fs-4"  i18n></h5>
        <div class="col-md-7 mt-5 text-center">
            <h4  i18n>1. Cereali contenenti glutine, cioè: grano, segale, orzo, avena, farro,
                kamut o i loro ceppi ibridati e prodotti derivati, tranne:</h4>

            <p class="mt-5" i18n>
                a. sciroppi di glucosio a base di grano, incluso destrosio (¹);
                b. maltodestrine a base di grano (¹);
                c. sciroppi di glucosio a base di orzo;
                d. cereali utilizzati per la fabbricazione di distillati alcolici,incluso l’alcol
                etilico di origine agricola.
                
            </p>
            <h4  i18n>2. Crostacei e prodotti a base di crostacei.
            </h4>
                <h4 i18n>3. Uova e prodotti a base di uova.
                </h4>
                    <h4  i18n>4. Pesce e prodotti a base di pesce, tranne:</h4>
                    <p class="mt-5" i18n>
                        a. gelatina di pesce utilizzata come supporto per preparati di vitamine o
                        carotenoidi;
                        b. gelatina o colla di pesce utilizzata come chiarificante nella birra e nel
                        vino
                        
                    </p>

                        <h4  i18n>5. Arachidi e prodotti a base arachidi.</h4>
                            <h4  i18n>6. Soia e prodotti a base soia, tranne:
                            </h4>
                            <p class="mt-5" i18n>
                                a. olio e grasso di soia raffinato (¹);
                                b. tocoferoli misti naturali (E306), tocoferolo D-alfa naturale, tocoferolo
                                acetato D-alfa naturale, tocoferolo succinato D-alfa naturale a base di 
                                soia;
                                c. oli vegetali derivati da fitosteroli e fitosteroli esteri a base di soia;
                                d. estere di stanolo vegetale prodotto da steroli di olio vegetale a base di
                                soia
                            </p>
                            
        </div>
    </div>
</div>
        
                



    <div class="row mx-0 px-0 justify-content-center sfondosquamato pb-100">
        <div class="col-md-6 mt-200 row justify-content-center">
            
            <div class="col-md-12"></div>
            <h5 class="col-md-12 tit-azz text-center fs-4"  i18n></h5>
            <div class="col-md-7 mt-5 text-center">
                <h4  i18n>7. Latte e prodotti a base di latte (incluso lattosio), tranne:</h4>
<p class="mt-5" i18n>
    a. siero di latte utilizzato per la fabbricazione di distillati alcolici, incluso
    l’alcol etilico di origine agricola;
    b. lattiolo
</p>
<h4  i18n>8. Frutta a guscio, vale a dire: mandorle (Amygdalus communis L.),
    nocciole (Corylus avellana), noci (Junglas regia), noci di acagiù
    (Anacardium occidentale), noci di pecan [Carya illinoinensis (Wangenh.) 
    K.Koch], noci del Brasile (Bertholletia excelsa), pistacchi (Pistacia vera), 
    noci macadamia o noci del Queensland (Macadamia ternifolia), e i loro 
    prodotti, tranne per la frutta a guscio utilizzata per la fabbricazione di 
    distillati alcolici, incluso l’alcol etilico di origine agricola.
</h4><br>
<h4  i18n>9. Sedano e prodotti a base di sedano.</h4><br>
<h4  i18n>10. Senape prodotti a base di senape.<br>
</h4>
<h4  i18n>11. Semi di sesamo e prodotti a base di Semi di sesamo</h4><br>
<h4  i18n>12. Anidride solforosa e solfiti in concentrazioni superiori a 10 mg/Kg o
    10 mg/L in termini di SO2 totale da calcolarsi per i prodotti così come
    proposti pronti al consumo o ricostituiti conformemente alle istruzioni 
    dei fabbricanti.<br>
</h4>
<h4  i18n>13. Lupini e prodotti a base lupini.
</h4><br>
<h4 i18n>14. Molluschi e prodotti a base molluschi
</h4><br>
<p class="mt-5" i18n>(¹) e i prodotti derivati, nella misura in cui la trasformazione che hanno 
    subito non è suscettibile di elevare il livello di allergenicità valutato dall’ 
    Autorità per il prodotto di base da cui sono derivati</p>
              
                    
            
            </div>
        </div>
    </div>